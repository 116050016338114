import styled from "styled-components";

export const StepContent = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacings.xs5};
  overflow: hidden;
`;

export const StepperContainer = styled.div`
  position: relative;
  width: 276px;
  padding: 8px 16px 8px 16px;
  background: white;
  border-radius: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.neutral[90]};
`;
export const StepDescription = styled.div<{ $isActive: boolean }>`
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  max-height: ${({ $isActive, theme }) => ($isActive ? theme.spacings.l : "0")};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const StepWrapper = styled.div<{ $active: boolean; $isLast: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ $isLast, theme }) =>
    $isLast ? "0" : theme.spacings.xs3};
  transition: margin-bottom 0.3s ease;

  &:hover ${({ $active }) => ($active ? "" : "div")} {
    border-color: ${({ $active, theme }) =>
      $active ? "" : theme.colors.secondary[40]};
  }
`;

export const StepIcon = styled.div<{
  $isActive: boolean;
  $isCompleted: boolean;
}>`
  margin-right: ${({ theme }) => theme.spacings.xs2};
  color: ${({ $isActive, $isCompleted, theme }) =>
    $isCompleted
      ? theme.colors.feedback.success[40]
      : $isActive
      ? theme.colors.secondary[40]
      : theme.colors.neutral[70]};
  flex-shrink: 0;
  transition: color 0.3s ease;
  svg {
    display: block;
    width: ${({ theme }) => theme.spacings.xs};
    height: ${({ theme }) => theme.spacings.xs};
  }
`;

export const StepTitle = styled.div<{
  $isActive: boolean;
  $isCompleted: boolean;
}>`
  color: ${({ $isActive, $isCompleted, theme }) =>
    $isActive
      ? theme.colors.secondary[40]
      : $isCompleted
      ? "#83727D"
      : "#53464F"};
  margin-bottom: ${({ theme }) => theme.spacings.xs4};
  transition: color 0.3s ease;
`;

export const VerticalLine = styled.div<{ $isActive: boolean }>`
  position: absolute;
  left: 11.5px;
  top: 22px;
  bottom: -16px;
  width: 1px;
  background: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.secondary[40] : theme.colors.neutral[90]};
  z-index: 0;
  transition: background 0.3s ease;
`;
