import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routesV2 } from "src/routes";
import { useRolesAndPermissionsQuery } from "../PageRolePermissions/queries";
import { Header } from "./components/Header/role-header";
import { PageRoleSkeleton } from "./components/Skeleton/role-page-skeleton";
import { RoleTabs } from "./components/Tabs/role-tabs";

export const PageRole = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const { data, isLoading } = useRolesAndPermissionsQuery(roleId!);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <PageRoleSkeleton />;

  return (
    <PageContainer>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton variant="neutral" onClick={() => navigate("/settings")}>
            Configurações
          </LinkButton>,
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routesV2.pageRoles)}
          >
            Perfis de permissão
          </LinkButton>,
          <LinkButton variant="neutral" onClick={() => null}>
            {data?.role?.name}
          </LinkButton>,
        ]}
      />
      <Header role={data?.role} />
      <RoleTabs role={data?.role} modules={data?.modules || []} />
    </PageContainer>
  );
};
