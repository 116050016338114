import TransferList from "@components/TransferListV2";
import { RootProps } from "@components/TransferListV2/Root";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { Container, CustomTableSpacing } from "./styles";
export type transferListProps<T> = {
  columns: any[];
  data: T[];
  loading: boolean;
  pagination: PaginationState;
  tableTitle: string;
  tableTagLabel: string;
  onSearchChange: (value: string) => void;
  onPaginationChange: (value: PaginationState) => void;
  selected: RootProps<T>["selected"];
  setSelected: RootProps<T>["setSelected"];
  dataSize: number;
};

export default function CompanyEmployeesTransferList<
  T extends object & { id: string; name: string }
>({
  columns,
  data,
  loading,
  pagination,
  tableTitle,
  tableTagLabel,
  onSearchChange,
  onPaginationChange,
  selected,
  setSelected,
  dataSize,
}: transferListProps<T>) {
  return (
    <Container>
      <TransferList.Root selected={selected} setSelected={setSelected}>
        <CustomTableSpacing>
          <TransferList.Table
            columns={columns}
            data={data}
            loading={loading}
            pagination={pagination}
            tableTitle={tableTitle}
            tableTagLabel={tableTagLabel}
            onSearchChange={onSearchChange}
            onPaginationChange={onPaginationChange}
            dataSize={dataSize}
          />
        </CustomTableSpacing>
        <TransferList.SelectedListArea />
      </TransferList.Root>
    </Container>
  );
}
