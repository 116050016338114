import { EmptyStateType } from "@components/EmployeesTable/components/EmptyState/employees-table-empty-state";
import { BulkAction, EmployeesTable } from "@components/index";
import { useDeleteActions } from "@components/TransferListV2/selectedList/list/useDeleteActions";
import { WarningModal } from "@components/WarningModal/warning-modal";
import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useCachedRole } from "@utils/useCachedRole";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Role, RoleModule } from "src/types";
import { RoleMembersActions } from "./components/MembersActions/role-members-actions";
import { RolePermissions } from "./components/RolePermissions/role-permissions";
import { Container } from "./styles";

enum Tabs {
  MEMBERS = 0,
  PERMISSIONS = 1,
}

type tabItem = {
  label: string;
  component: JSX.Element;
};

interface RoleTabsProps {
  role?: Role;
  modules?: RoleModule[];
}

interface LocationState {
  preSelectedTab: Tabs.MEMBERS | Tabs.PERMISSIONS;
}

export const RoleTabs = ({ role, modules }: RoleTabsProps) => {
  const location = useLocation();
  const { preSelectedTab } = (location.state as LocationState) || {};
  const [selectedTab, setSelectedTab] = useState<Tabs>(
    preSelectedTab || Tabs.MEMBERS
  );
  const { roleId } = useParams() as { roleId: string };
  const { selectedCompany } = useSelectedCompany();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ id: string; name: string }[]>([]);
  const { deleteEmployeeFromRole } = useDeleteActions();
  const cachedRole = useCachedRole(roleId);

  const bulkDeleteAction: BulkAction = {
    key: "deleteEmployeeFromRole",
    label: "Remover membros deste perfil de permissão",
    onClick: (selected: { id: string; name: string }[]) => {
      setSelected(selected);
      setIsModalOpen(true);
    },
    icon: "IconUserMinus",
  };

  const handleBulkDelete = async (selected: { id: string; name: string }[]) => {
    await Promise.all(
      selected.map(({ id, name }) =>
        deleteEmployeeFromRole({
          role: { id: roleId, name: cachedRole?.name || "" },
          employee: { id, name },
        })
      )
    );
  };

  const membersLabel = useMemo(() => {
    return role?.membersCount && role?.membersCount > 0
      ? `Membros (${role.membersCount})`
      : `Membros`;
  }, [role]);

  const filteredFeatures = useMemo(() => {
    if (role && modules) {
      const permissionsSet = new Set(role?.permissions);
      return modules?.flatMap((module) =>
        module.features
          .map((feature) => ({
            ...feature,
            permissions: feature.permissions.filter((permission) =>
              permissionsSet.has(permission.id)
            ),
          }))
          .filter((feature) => feature.permissions.length > 0)
      );
    }
    return [];
  }, [modules, role]);

  const tabItems: tabItem[] = [
    {
      label: membersLabel,
      component: (
        <EmployeesTable
          companyId={selectedCompany.id}
          employeeIds={role?.employees}
          CustomActions={RoleMembersActions}
          emptyStateType={EmptyStateType.MEMBERS}
          bulkActions={[bulkDeleteAction]}
        />
      ),
    },
    {
      label: "Permissões",
      component: <RolePermissions role={role} features={filteredFeatures} />,
    },
  ];
  return (
    <Container>
      <Tab
        selected={selectedTab}
        onTabChanged={setSelectedTab}
        tabItens={tabItems}
      />
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        modalSize="xs"
        isOpen={isModalOpen}
        title="Você tem certeza que deseja remover estas pessoas deste perfil de permissão?"
        description="Ao desvincular estas pessoas do perfil, elas perderão todas as permissões vinculadas ao perfil. Certifique-se de que essa alteração é necessária."
        footerConfig={{
          text: "Sim, remover",
          icon: "IconTrash",
          buttonSize: "small",
          variant: "primary",
          iconColor: "negative",
        }}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          handleBulkDelete(selected);
          setIsModalOpen(false);
        }}
      />
    </Container>
  );
};
