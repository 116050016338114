import { EmployeeDetails } from "@components/EmployeesTable/components/EmployeeDetails/employee-details";
import { TagsColumn } from "@components/EmployeesTable/components/TagsColumn/tags-column";
import { useEmployeesTable } from "@components/EmployeesTable/hooks/useEmployeesTable";
import { handleTipClick, Tips } from "@components/Tips/tips";
import TransferList from "@components/TransferListV2";
import {
  Button,
  Focused,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { TableEmployee } from "bff/src/routers";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useEditRoleMembers,
  useEmployeeIdsByRoleQuery,
} from "src/pages/PageRoleEmployees/queries";
import { routesV2 } from "src/routes";
import { TruncateText } from "../PagePermissionsV2/components/Tabs/components/TruncateText/truncate-text";
import {
  Container,
  CustomTableSpacing,
  CustomTableTitle,
  LeftContainer,
  RightContainer,
} from "./styles";
import { useGetRoleMembers } from "./useGetRoleMembers";

export const PageAddMembers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const canGoBack = location.key !== "default";
  const backRoute = canGoBack ? -1 : routesV2.pageRoles;
  const { isAdmin } = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const { roleId } = useParams();
  const [members, setMembers] = useState<TableEmployee[]>([]);
  const { data: membersIds, isLoading: membersIdsLoading } =
    useEmployeeIdsByRoleQuery(roleId!);
  const { editRoleMembers, isLoading: editMembersLoading } = useEditRoleMembers(
    {
      redirectTo: backRoute,
    }
  );
  const { roleMembers, isLoading: roleMembersLoading } =
    useGetRoleMembers(membersIds);

  const columns = [
    {
      header: "Pessoa",
      accessorKey: "name",
      minSize: 180,
      cell: ({ row }: any) => (
        <EmployeeDetails
          employeeDetails={{
            name: row?.original?.name,
            documentNumber: row?.original?.documentNumber,
            status: row?.original?.status,
          }}
        />
      ),
    },
    {
      header: "Cargo",
      accessorKey: "role",
      minSize: 100,
      cell: ({ row }: any) => (
        <Typography.Body4 weight={600} color="neutral.20">
          <TruncateText text={row?.original?.role} limit={26} />
        </Typography.Body4>
      ),
    },
    {
      header: "Empresa que faz parte",
      accessorKey: "company",
      minSize: 150,
      cell: ({ row }: any) => (
        <TagsColumn
          tags={[row?.original?.company]}
          emptyLabel="Nome da empresa"
          tagVariant="gray"
        />
      ),
    },
  ];

  const {
    data: employeesData,
    fetchEmployees,
    isLoading: isLoadingEmployees,
    pagination,
    setPagination,
    searchQuery,
    setSearch,
  } = useEmployeesTable();

  const footerActions = useMemo(() => {
    const startActions = [
      <LinkButton
        variant="neutral"
        key="cancel"
        onClick={() => {
          navigate(backRoute as any);
        }}
      >
        Cancelar
      </LinkButton>,
    ];
    const endActions = [
      <Button
        variant="primary"
        size="medium"
        key="create"
        onClick={() =>
          editRoleMembers({
            roleId: roleId!,
            employeeIds: members.map((employee) => employee.id),
          })
        }
        loading={editMembersLoading}
      >
        Confirmar <Icons name="IconCheck" fill="transparent" />
      </Button>,
    ];

    return { startActions, endActions };
  }, [backRoute, roleId, members, editMembersLoading]);

  useEffect(() => {
    fetchEmployees({
      companyId: selectedCompany.id!,
      ...(isAdmin && { restrictToCompany: false }),
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      searchQuery: searchQuery,
    });
  }, [pagination, searchQuery, selectedCompany]);

  const parsedData = useMemo(() => {
    if (employeesData?.employees) {
      return employeesData?.employees.map((employee) => ({
        ...employee,
        metaData: {
          ...(employee.status === "INACTIVE" && { isDisabled: true }),
          ...(membersIds?.includes(employee.id) && {
            isMember: true,
          }),
        },
      }));
    }
    return [];
  }, [employeesData, membersIds]);

  useEffect(() => {
    if (roleMembers?.length) {
      setMembers(
        roleMembers.map((member) => ({
          ...member,
          metaData: { isMember: true },
        }))
      );
    }
  }, [roleMembers]);

  const tips = [
    <Typography.Body4 weight={600} color="neutral.30">
      1. Você pode adicionar membros desta empresa ou de todo o grupo econômico
      a uma permissão usando a lista ao lado.
    </Typography.Body4>,
    <Typography.Body4 weight={600} color="neutral.30">
      2. Não encontrou uma pessoa na lista? Para adicionar um membro a uma
      permissão, ele precisa estar cadastrado na plataforma.
      <LinkButton variant="default" onClick={() => handleTipClick(navigate)}>
        {" "}
        Envie um convite de acesso
      </LinkButton>
      para que essa pessoa crie sua conta e já entre com a nova permissão
      atribuída.
    </Typography.Body4>,
  ];

  const isLoading =
    membersIdsLoading || isLoadingEmployees || roleMembersLoading;

  return (
    <Focused
      hasHeader={isLoading ? false : true}
      containerStyle={{
        // Workaround for hiding menu
        // until refactor
        position: "fixed",
        width: "100%",
        background: "#fff",
        top: 0,
        left: 0,
      }}
      title="Adicionar pessoas"
      routes={[
        {
          label: "< Voltar para Perfis de Permissão",
          route: `${routesV2.pageRoles}`,
        },
      ]}
      footer={footerActions}
    >
      <Container>
        <LeftContainer>
          <Tips title={"Dicas"} tips={tips} />
        </LeftContainer>
        <RightContainer>
          <Container>
            <TransferList.Root selected={members} setSelected={setMembers}>
              <CustomTableSpacing>
                <CustomTableTitle>
                  <Typography.Headline8 color="neutral.20">
                    Para quais pessoas você quer aplicar esta permissão?
                  </Typography.Headline8>
                  <Typography.Body4 color="neutral.30">
                    Selecione abaixo as pessoas que devem ter acesso a esta
                    permissão.
                  </Typography.Body4>
                </CustomTableTitle>
                <TransferList.Table
                  columns={columns}
                  data={parsedData}
                  loading={isLoading}
                  pagination={pagination}
                  onSearchChange={setSearch}
                  onPaginationChange={setPagination}
                  tableTagLabel={""}
                  tableTitle={""}
                  dataSize={employeesData?.metadata.totalCount || 0}
                />
              </CustomTableSpacing>
              <TransferList.SelectedListArea />
            </TransferList.Root>
          </Container>
        </RightContainer>
      </Container>
    </Focused>
  );
};
