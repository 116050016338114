import { RoleTypeTag } from "@components/index";
import {
  Menu,
  MenuOption,
  PillButton,
  Table,
  tableControllers,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { normalizeText } from "@utils/normalizeText";
import { useMemo, useState } from "react";
import { Permission } from "src/pages/PageRolePermissions/types";
import { TableViews } from "../../types";
import { TruncateText } from "../TruncateText/truncate-text";
import { AuditColumn } from "./components/AuditColumn/permissions-audit-column";
import { PermissionsActions } from "./components/PermissionsActions/permissions-actions";
import { PermissionsTableEmptyState } from "./components/PermissionsTableEmptyState/permissions-table-empty-state";
import { usePermissionsTable } from "./hooks/usePermissionsTable";

interface PermissionsTableProps {
  permissions: Permission[];
  isLoading: boolean;
}

export const PermissionsTable = ({
  permissions,
  isLoading,
}: PermissionsTableProps) => {
  const {
    search,
    setSearch,
    pagination,
    setPagination,
    pageSizeOptions,
    tableView,
    setTableView,
  } = usePermissionsTable();
  const [orderBy, setOrderBy] = useState<"up" | "down" | undefined>(undefined);

  const { filteredData, totalFiltered } = useMemo(() => {
    let filtered = permissions.filter((permission) => {
      if (tableView === TableViews.CUSTOM) return !permission.builtIn;
      if (tableView === TableViews.DEFAULT) return permission.builtIn;
      return true;
    });
    if (search) {
      const normalizedSearch = normalizeText(search);
      filtered = filtered.filter((permission) => {
        const name = permission.name ? normalizeText(permission.name) : "";
        const description = permission.description
          ? normalizeText(permission.description)
          : "";
        return `${name} ${description}`.includes(normalizedSearch);
      });
    }
    const sorted = [...filtered];
    if (orderBy) {
      sorted.sort((a, b) => {
        const countA = a.membersCount ?? 0;
        const countB = b.membersCount ?? 0;
        return orderBy === "up" ? countB - countA : countA - countB;
      });
    }
    const { pageNumber, pageSize } = pagination;
    const paginated = sorted.slice(
      (pageNumber - 1) * pageSize,
      pageNumber * pageSize
    );
    return {
      filteredData: paginated,
      totalFiltered: sorted.length,
    };
  }, [permissions, tableView, search, orderBy, pagination]);

  const table = tableControllers.useTableColumns<Permission>({
    defaultColumn: {
      minSize: 150,
    },
    total: totalFiltered,
    columns: [
      {
        header: "Nome do perfil",
        accessorKey: "name",
        size: 250,
        cell: ({ row }: any) => (
          <Typography.Body4 weight={600} color="neutral.20">
            {row?.original?.name}
          </Typography.Body4>
        ),
      },
      {
        header: "Descrição",
        accessorKey: "description",
        size: 250,
        cell: ({ row }: any) => (
          <TruncateText text={row?.original?.description} limit={70} />
        ),
      },
      { header: "Membros", accessorKey: "membersCount", minSize: 150 },
      {
        header: "Tipo",
        accessorKey: "builtIn",
        size: 150,
        cell: ({ row }: any) => (
          <RoleTypeTag builtIn={row?.original?.builtIn} />
        ),
      },
      {
        header: "Criação",
        accessorKey: "createdAt",
        size: 150,
        cell: ({ row }: any) => (
          <AuditColumn
            date={row?.original?.createdAt}
            agent={row?.original?.createdBy}
          />
        ),
      },
      {
        header: "Modificação",
        accessorKey: "updatedAt",
        size: 150,
        cell: ({ row }: any) => (
          <AuditColumn
            date={row?.original?.updatedAt}
            agent={row?.original?.modifiedBy}
          />
        ),
      },
      {
        accessorKey: "actions",
        size: 150,
        cell: ({ row }) => (
          <PermissionsActions
            builtIn={row?.original?.builtIn}
            roleId={row?.original?.id}
          />
        ),
      },
    ],
    data: filteredData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });
  const emptyStateConditions = !isLoading && !filteredData?.length;
  return (
    <Table.Root variant="soft">
      <Table.Header title="" />
      <Table.Content>
        <Table.FilterSearch
          onSearch={(input) => setSearch(normalizeText(input.target.value))}
          visions={[
            { id: TableViews.ALL, label: "Todas" },
            { id: TableViews.DEFAULT, label: "Padrão" },
            { id: TableViews.CUSTOM, label: "Personalizadas" },
          ]}
          onChangeVision={(id: TableViews) => setTableView(id)}
          vision={tableView}
          listType="line"
        >
          <Menu
            type="default"
            options={[
              {
                children: (
                  <MenuOption
                    label="Membros (do maior para o menor)"
                    icon="IconArrowUp"
                  />
                ),
                onClick: () => setOrderBy("up"),
              },
              {
                children: (
                  <MenuOption
                    label="Membros (do menor para o maior)"
                    icon="IconArrowDown"
                  />
                ),
                onClick: () => setOrderBy("down"),
              },
            ]}
          >
            <PillButton
              icon="IconArrowsSort"
              label="Ordenar por"
              onClick={() => null}
              variant="default"
              size="small"
              type="primary"
            />
          </Menu>
        </Table.FilterSearch>
        {emptyStateConditions ? (
          <PermissionsTableEmptyState searchQuery={search} />
        ) : (
          <Table.Grid.Root>
            <Table.Grid.Header
              getHeaderGroups={table.getHeaderGroups}
              toggleAllRowsExpanded={table.toggleAllRowsExpanded}
            />
            {table.rows.map((row, index) => (
              <Table.Grid.Row key={index + row.id} row={row} />
            ))}
          </Table.Grid.Root>
        )}

        <Table.Pagination
          count={totalFiltered}
          onPaginationChange={({ pageSize, pageNumber }) =>
            setPagination({ ...pagination, pageSize, pageNumber })
          }
          showItemRange
          pagination={pagination}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Content>
    </Table.Root>
  );
};
