import { Tag, TagProps, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { TruncateText } from "../../../../../../pages/PagePermissionsV2/components/Tabs/components/TruncateText/truncate-text";

export const EmployeeEntityTag = ({
  employeeTag,
  emptyLabel,
  tagVariant,
}: {
  employeeTag?: { id: string; name: string };
  emptyLabel?: string;
  tagVariant?: TagProps["variant"];
}) => {
  const style = { minWidth: "max-content" };

  const tagContent = employeeTag ? (
    <TruncateText text={employeeTag.name} limit={30} />
  ) : (
    emptyLabel
  );

  return (
    <Tag
      variant={tagVariant ? tagVariant : employeeTag ? "pink" : "gray"}
      as="label"
      style={style}
    >
      <Typography.Caption
        weight={600}
        color={tagVariant ? undefined : employeeTag ? "brand.50" : undefined}
      >
        {tagContent}
      </Typography.Caption>
    </Tag>
  );
};
