import styled from "styled-components";

export const TipTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacings.xs2};
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const TipListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.xs2};
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 276px;
  padding: ${({ theme }) => theme.spacings.xs2};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.spacings.xs4};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  justify-content: flex-start;
`;
