import { useDeleteActions } from "@components/TransferListV2/selectedList/list/useDeleteActions";
import { WarningModal } from "@components/WarningModal/warning-modal";
import {
  Icons,
  IconsProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useStableHover } from "@utils/useStableHover";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "src/pages/PagePermissionsV2/components/Tabs/types";
import { useTheme } from "styled-components";
import { SuperAdminsMenu } from "../SuperAdminsMenu/super-admins-menu";
import { Container, QuickAction, Wrapper } from "./styles";

interface SuperAdminActionsProps {
  employeeName: string;
  employeeId: string;
}

export const SuperAdminActions = ({
  employeeId,
  employeeName,
}: SuperAdminActionsProps) => {
  const { containerRef, isHovered } = useStableHover();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { deleteSuperAdmin } = useDeleteActions();

  const actions = useMemo<Action[]>(
    () => [
      {
        label: "Ver perfil da pessoa",
        icon: "IconEye" as IconsProps["name"],
        key: "view",
        onClick: () => navigate(`/employees/${employeeId}/profile`),
      },
      {
        label: "Remover super administrador",
        icon: "IconUserMinus" as IconsProps["name"],
        key: "remove",
        onClick: () => setIsModalOpen(true),
      },
    ],
    [employeeId, navigate]
  );

  return (
    <Wrapper>
      <Container ref={containerRef} $hovered={isHovered}>
        {isHovered ? (
          <>
            <QuickAction
              $borderColor={theme.colors.neutral[100]}
              onClick={() => navigate(`/employees/${employeeId}/profile`)}
            >
              <Typography.Caption weight={700}>
                Ver perfil da pessoa
              </Typography.Caption>
              <Icons name="IconEye" size={16} fill="transparent" />
            </QuickAction>
            <QuickAction
              $borderColor={theme.colors.feedback.error[40]}
              onClick={() => setIsModalOpen(true)}
            >
              <Typography.Caption color="feedback.error.40" weight={700}>
                Remover super administrador
              </Typography.Caption>
              <Icons
                name="IconUserMinus"
                size={16}
                fill="transparent"
                color={theme.colors.feedback.error[40]}
              />
            </QuickAction>
            <SuperAdminsMenu actions={actions} isHovered={isHovered} />
          </>
        ) : (
          <>
            <Icons name="IconEye" size={16} fill="transparent" />
            <Icons name="IconUserMinus" size={16} fill="transparent" />
            <SuperAdminsMenu actions={actions} isHovered={isHovered} />
          </>
        )}
      </Container>
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        modalSize="xs"
        isOpen={isModalOpen}
        title="Você tem certeza que deseja remover esta pessoa do perfil de super administradores?"
        description="Ao desvincular esta pessoa do perfil de super administradores, ela não terá mais permissão para gerenciar informações e configurações das empresas do grupo econômico. Certifique-se de que essa alteração é essencial."
        footerConfig={{
          text: "Sim, remover",
          icon: "IconTrash",
          buttonSize: "small",
          variant: "primary",
          iconColor: "negative",
        }}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          deleteSuperAdmin(employeeId);
          setIsModalOpen(false);
        }}
      />
    </Wrapper>
  );
};
