import { TagProps } from "@flash-tecnologia/hros-web-ui-v2";
import { EmployeeEntityTag } from "./component/Tag/employee-entity-tag";
import { TagsCount } from "./component/TagsCount/tags-count";
import { Container } from "./styles";

export const TagsColumn = ({
  tags,
  emptyLabel,
  tagVariant,
}: {
  tags: { id: string; name: string }[];
  emptyLabel: string;
  tagVariant?: TagProps["variant"];
}) => {
  return (
    <Container>
      {tags?.length ? (
        <>
          <EmployeeEntityTag employeeTag={tags[0]} tagVariant={tagVariant} />
          <TagsCount tagQt={tags.length - 1} />
        </>
      ) : (
        <EmployeeEntityTag employeeTag={undefined} emptyLabel={emptyLabel} />
      )}
    </Container>
  );
};
