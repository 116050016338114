import {
  Icons,
  PillButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { routesV2 } from "src/routes";
import {
  BannerDescription,
  Container,
  IconContainer,
  LeftContent,
  RightContent,
} from "./styled";

export const Banner = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <LeftContent>
        <IconContainer>
          <Icons
            size={16}
            name={"IconShieldCheckFilled"}
            color={"neutral.40"}
          />
        </IconContainer>
        <BannerDescription>
          <Typography.Body4 weight={700} color={"neutral.30"}>
            Superadministradores
          </Typography.Body4>
          <Typography.Body4 color={"neutral.40"}>
            São usuários que têm acesso total a todas as empresas do grupo
            econômico e podem gerenciar permissões e dados de todas elas. Use o
            botão ao lado para adicionar ou remover superadministradores da
            plataforma.
          </Typography.Body4>
        </BannerDescription>
      </LeftContent>
      <RightContent>
        <PillButton
          onClick={() => navigate(routesV2.pageGlobalAdmins)}
          variant="default"
          size="extra-small"
          type="secondary"
          icon={"IconUserPlus"}
          label="Gerenciar super administradores"
        />
      </RightContent>
    </Container>
  );
};
