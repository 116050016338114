import { WarningModal } from "@components/WarningModal/warning-modal";
import { useMemo, useState } from "react";
import { DataType } from "../../../Root";
import { useDeleteActions } from "../../../selectedList/list/useDeleteActions";
import { CustomCheckBox } from "../../styled";

export const TableRowCheckbox = <T extends DataType>({
  rowData,
  isAdminPath,
  selected,
  role,
  addSelected,
  removeSelected,
}: {
  rowData: T;
  isAdminPath: boolean;
  selected: T[];
  role: {
    id: string;
    name: string;
  };
  addSelected: (items: T[]) => void;
  removeSelected: (items: T[]) => void;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { deleteSuperAdmin, deleteEmployeeFromRole } = useDeleteActions();

  const { title, description, onSubmit } = useMemo(
    () => ({
      title: isAdminPath
        ? "Você tem certeza que deseja remover esta pessoa do perfil de super administradores?"
        : "Você tem certeza que deseja remover esta pessoa deste perfil de permissão?",
      description: isAdminPath
        ? "Ao desvincular esta pessoa do perfil de super administradores, ela não terá mais permissão para gerenciar informações e configurações das empresas do grupo econômico."
        : "Ao desvincular esta pessoa do perfil, ela não terá mais as permissões vinculadas ao perfil.",
      onSubmit: () => {
        if (isAdminPath) {
          deleteSuperAdmin(rowData?.id);
        } else if (role.id) {
          deleteEmployeeFromRole({
            role,
            employee: { id: rowData?.id, name: rowData?.name },
          });
        }
        removeSelected([rowData]);
        setModalIsOpen(false);
      },
    }),
    [
      isAdminPath,
      role,
      rowData.id,
      deleteSuperAdmin,
      deleteEmployeeFromRole,
      removeSelected,
      setModalIsOpen,
    ]
  );

  const isMember = rowData.metaData?.isMember;
  const isChecked = selected.some((item) => item.id === rowData.id);

  return (
    <>
      <CustomCheckBox
        checked={isMember || isChecked}
        disabled={rowData.metaData?.isDisabled}
        onChange={(e) => {
          if (e.target.checked) {
            addSelected([rowData]);
          } else {
            isMember ? setModalIsOpen(true) : removeSelected([rowData]);
          }
        }}
      />
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        modalSize="xs"
        isOpen={modalIsOpen}
        title={title}
        description={description}
        footerConfig={{
          icon: "IconTrash",
          text: "Sim, remover",
          buttonSize: "small",
          variant: "primary",
          iconColor: "negative",
        }}
        onClose={() => setModalIsOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};
