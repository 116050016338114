import {
  ShapeIcon,
  TextArea,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FormikProps } from "formik";
import { FormValues } from "../../pages/PageRoleCreate/create-role-context";
import {
  BannerContainer,
  Container,
  CustomTextArea,
  FormContainer,
  HeaderContainer,
} from "./styles";

interface BasicInfoStepProps {
  formik: FormikProps<FormValues>;
}

export const BasicInfoStep = ({ formik }: BasicInfoStepProps) => {
  return (
    <Container>
      <HeaderContainer>
        <Typography.Headline8 color="neutral.20">
          {" "}
          Informações básicas da informação
        </Typography.Headline8>
        <Typography.Body4 color="neutral.30">
          Dê um nome à permissão e escreva uma descrição objetiva que explique
          sua finalidade.
        </Typography.Body4>
      </HeaderContainer>
      <FormContainer>
        <BannerContainer>
          <ShapeIcon variant="disabled" name={"IconBulb"} size={24} />
          <Typography.Caption color="neutral.30">
            {
              "Nomear e descrever uma permissão é fundamental para garantir clareza sobre sua função, evitando erros e facilitando a gestão."
            }
          </Typography.Caption>
        </BannerContainer>
        <CustomTextArea
          maxLength={50}
          required
          placeholder="Nome da permissão"
          value={formik.values.name}
          onChange={(e: any) => {
            formik.handleChange({
              target: { id: "name", value: e.target.value },
            });
          }}
          errorMessage={formik.errors.name}
        />
        <TextArea
          maxLength={250}
          height={30}
          required={false}
          value={formik.values.description}
          placeholder="Descrição clara que explique a função da permissão."
          onChange={(e: any) => {
            const value = e.target.value;
            formik.handleChange({ target: { id: "description", value } });
          }}
          errorMessage={formik.errors.description}
        />
      </FormContainer>
    </Container>
  );
};
