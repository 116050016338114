import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useMemo } from "react";
import { Role } from "src/types";

export const useCachedRole = (roleId: string): Role | undefined => {
  const { selectedCompany } = useSelectedCompany();
  const client = trpc.useContext();

  const cachedRoles = client.listRoles.getData({
    companyId: selectedCompany?.id ?? "",
  });

  return useMemo(() => {
    if (!roleId || !cachedRoles) return undefined;
    return cachedRoles.find((role) => role.id === roleId);
  }, [cachedRoles, roleId]);
};
