import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

export const RoleTypeTag = ({ builtIn }: { builtIn: boolean }) => {
  const style = { minWidth: "max-content" };
  if (builtIn) {
    return (
      <Tag variant="primary" as={"label"} style={style}>
        Padrão
      </Tag>
    );
  }
  return (
    <Tag variant="gray" as={"label"} style={style}>
      Personalizada
    </Tag>
  );
};
