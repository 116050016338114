import { HighLightSearchMatch } from "@components/HighLightSearchMatch/highlight-search-match";
import { Icons, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { FormikProps } from "formik";
import { useCallback } from "react";
import { FormValues } from "src/pages/PageRoleCreate/create-role-context";
import { StyledCheckBox } from "src/pages/PageRoleCreate/styles";
import { useTheme } from "styled-components";
import { Container, LeftContainer, RightContainer } from "./styles";
interface PermissionOption {
  permission: {
    id: string;
    name: string;
    description?: string;
  };
  searchQuery?: string;
  formik: FormikProps<FormValues>;
}

export const PermissionOption = ({
  permission,
  searchQuery = "",
  formik,
}: PermissionOption) => {
  const theme = useTheme();

  const isSelected = formik.values?.permissions.includes(permission.id);

  const handleSelect = useCallback(() => {
    const currentPermissions = formik.values?.permissions;
    const newPermissions = currentPermissions.includes(permission.id)
      ? currentPermissions.filter((id) => id !== permission.id)
      : [...currentPermissions, permission.id];

    formik.setFieldValue("permissions", newPermissions);
  }, [formik, permission.id]);

  return (
    <Container>
      <LeftContainer>
        <Typography.Body4 weight={400} color="neutral.30">
          {HighLightSearchMatch({
            text: permission.name,
            searchQuery: searchQuery,
          })}
        </Typography.Body4>
        {permission.description && (
          <Tooltip title={permission.description} placement="right-start">
            <Icons
              name="IconInfoCircle"
              size={12}
              color={theme.colors.neutral[30]}
            />
          </Tooltip>
        )}
      </LeftContainer>
      <RightContainer>
        <StyledCheckBox checked={isSelected} onChange={handleSelect} />
      </RightContainer>
    </Container>
  );
};
