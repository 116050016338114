import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";
import { useDebouncedValue } from "@utils/useDebouncedValue";
import { useCallback, useState } from "react";
import { PaginationState } from "src/pages/PagePermissions/types";

const SEARCH_INPUT_DELAY = 300;
export const useEmployeesTable = () => {
  const { data, mutate, isLoading } = trpc.getEmployeesTable.useMutation();
  const [search, setSearch] = useState<string>("");
  const searchQuery = useDebouncedValue(search, SEARCH_INPUT_DELAY);
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });

  const pageSizeOptions = [
    { label: "5 itens", value: 5 },
    { label: "10 itens", value: 10 },
    { label: "25 itens", value: 25 },
  ];
  const fetchEmployees = useCallback(
    (input: {
      employeeIds?: string[];
      searchQuery?: string;
      restrictToCompany?: boolean;
      page: number;
      pageSize: number;
      companyId: string;
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os colaboradores",
            type: "error",
          });
        },
      });
    },
    [mutate]
  );

  return {
    data,
    fetchEmployees,
    isLoading,
    pagination,
    pageSizeOptions,
    setPagination,
    searchQuery,
    setSearch,
  };
};
