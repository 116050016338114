import { Route, Routes } from "react-router-dom";
import { PagePermissions, PageRolePermissions } from "src/pages";
import { PageAddMembers } from "src/pages/PageAddMembers/add-members-page";
import { PageGlobalAdmins } from "src/pages/PageGlobalAdmins";
import { PagePermissionsCreate } from "src/pages/PagePermissionsCreate";
import { PagePermissionsV2 } from "src/pages/PagePermissionsV2/permissions-page";
import { PageRole } from "src/pages/PageRole/role-page";
import { CreateRoleProvider } from "src/pages/PageRoleCreate/create-role-context";
import { PageRoleCreate } from "src/pages/PageRoleCreate/create-role-page";
import { EditRoleProvider } from "src/pages/PageRoleEdit/edit-role-context";
import { PageRoleEdit } from "src/pages/PageRoleEdit/edit-role-page";
import { PageRoleEmployees } from "src/pages/PageRoleEmployees";
import PageSetAdmins from "src/pages/PageSetAdmins";
import { PageSetSuperAdmins } from "src/pages/PageSetSuperAdmins/set-super-admins-page";
import { PageSuperAdmins } from "src/pages/PageSuperAdmins/super-admins-page";
import { RoutesGuard } from "./routesGuard";

export const routes = {
  pageInitial: "/settings",
  pageRoles: "/settings/permissions",
  pageRolesCreate: "/settings/permissions/create",
  pageRolesPermissions: "/settings/permissions/:roleId/permissions",
  pageRolesEmployees: "/settings/permissions/:roleId/employees",
  pageGlobalAdmins: "/settings/permissions/administrators",
  pageSetGlobalAdmins: "/settings/permissions/administrators/add",
} as const;

export const routesV2 = {
  pageInitial: "/settings",
  pageRoles: "/settings/permissions/view",
  pageRolesCreate: "/settings/permissions/create/new",
  pageRolesEdit: "/settings/permissions/view/:roleId/edit",
  pageRolesAddMembers: "/settings/permissions/view/:roleId/add-members",
  pageRolesPermissions: "/settings/permissions/view/:roleId/",
  pageGlobalAdmins: "/settings/permissions/administrators/view",
  pageSetGlobalAdmins: "/settings/permissions/administrators/view/add",
} as const;

const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={<RoutesGuard permission="core_manage_company_settings" />}
      >
        <Route path={routes.pageRoles}>
          <Route index element={<PagePermissions />} />
          <Route path=":roleId" element={<PagePermissions />} />
          <Route path=":roleId/permissions" element={<PageRolePermissions />} />
          <Route path=":roleId/employees" element={<PageRoleEmployees />} />
          <Route path="create" element={<PagePermissionsCreate />} />
        </Route>
      </Route>

      <Route
        element={<RoutesGuard permission="core_manage_company_settings" />}
      >
        <Route path={routesV2.pageRoles}>
          <Route index element={<PagePermissionsV2 />} />
          <Route path=":roleId" element={<PageRole />} />
          <Route path=":roleId/add-members" element={<PageAddMembers />} />
          <Route
            path={":roleId/edit"}
            element={
              <EditRoleProvider>
                <PageRoleEdit />
              </EditRoleProvider>
            }
          />
        </Route>
        <Route
          path={routesV2.pageRolesCreate}
          element={
            <CreateRoleProvider>
              <PageRoleCreate />
            </CreateRoleProvider>
          }
        />
      </Route>
      <Route element={<RoutesGuard permission="admin" />}>
        <Route path={routes.pageGlobalAdmins} element={<PageGlobalAdmins />} />
        <Route path={routes.pageSetGlobalAdmins} element={<PageSetAdmins />} />
        <Route path={routesV2.pageGlobalAdmins} element={<PageSuperAdmins />} />
        <Route
          path={routesV2.pageSetGlobalAdmins}
          element={<PageSetSuperAdmins />}
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
