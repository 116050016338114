import { EmployeeStatus } from "src/types";
import styled from "styled-components";

const getBackgroundColor = (
  status: EmployeeStatus,
  theme: any,
  shade: number
) => {
  const statusColors = {
    ACTIVE: theme.colors.status.complete[shade],
    INACTIVE: theme.colors.status.inactive[shade],
    INVITATION_PENDING: theme.colors.status.attention[shade],
    INVITATION_SCHEDULED: theme.colors.status.attention[shade],
    INVITATION_EXPIRED: theme.colors.status.attention[shade],
    INVITATION_SENT: theme.colors.status.attention[shade],
  };

  return statusColors[status];
};

export const OuterCircle = styled.div<{ $status: EmployeeStatus }>(
  ({ $status, theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "24px",
    background: getBackgroundColor($status, theme, 90),
    width: "14px",
    height: "14px",
    gap: "4px",
  })
);

export const InnerCircle = styled.div<{ $status: EmployeeStatus }>(
  ({ $status, theme }) => ({
    background: getBackgroundColor($status, theme, 50),
    width: "6px",
    height: "6px",
    borderRadius: "16px",
  })
);
