import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useEmployeeIdsByRoleQuery = (roleId: string) => {
  if (!roleId) return { data: [], isLoading: false };
  const { data, isLoading } = trpc.getEmployeesIdsByRole.useQuery({ roleId });

  return { data, isLoading };
};

export const useEmployeeQuery = () => {
  const { data, mutate, isLoading } = trpc.getEmployeesPaginated.useMutation();

  const fetch = useCallback(
    (input: {
      searchQuery?: string;
      page: number;
      pageSize: number;
      companyId?: string;
      includeProfilePicture?: boolean;
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os colaboradores",
            type: "error",
          });
        },
      });
    },
    [mutate]
  );

  return { data, fetch, isLoading };
};

export const useEditRoleMembers = ({ redirectTo }: { redirectTo: any }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedCompany } = useSelectedCompany();
  const { mutate } = trpc.editRoleEmployees.useMutation();
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const editRoleMembers = useCallback(
    (input: { roleId: string; employeeIds: string[] }) => {
      setIsLoading(true);
      mutate(input, {
        onSuccess: async (_, { roleId, employeeIds }) => {
          await utils.getRoleEmployeesPaginated.invalidate();
          await utils.listRoles.cancel();
          utils.listRoles.setData({ companyId: selectedCompany.id }, (prev) => {
            if (!prev) return prev;
            return prev.map((role) => {
              if (role.id === roleId) {
                return { ...role, membersCount: employeeIds.length };
              }
              return role;
            });
          });
          setIsLoading(false);
          dispatchToast({
            type: "success",
            content: "Perfil atualizado com sucesso!",
          });
          navigate(redirectTo, { state: { listRolesEnabled: false } });
        },
        onError: (error) => {
          dispatchToast({
            type: "error",
            content: "Erro ao Adicionar pessoas no perfil",
            description: error.message,
          });
        },
      });
    },
    [navigate, mutate, redirectTo, selectedCompany]
  );

  return { editRoleMembers, isLoading };
};
