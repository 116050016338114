import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

export const PageHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PageBannerContainer = styled.div`
  display: flex;
  witdh: 100%;
  margin-top: 40px;
`;

export const PageTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const PageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
  padding-top: 12px;
  gap: 12px;
`;
