import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  padding-right: ${({ theme }) => theme.spacings.xs1};
  gap: ${({ theme }) => theme.spacings.s};
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

export const CustomCheckBox = styled(Checkbox)`
  width: ${({ theme }) => theme.spacings.s};
  height: ${({ theme }) => theme.spacings.s};
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
