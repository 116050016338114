import { StatusTagV2 } from "@components/StatusTagV2/status-tag-v2";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { cpfFormater } from "@utils/cpfFormatter";
import { EmployeeStatus } from "src/types";
import { TruncateText } from "../../../../pages/PagePermissionsV2/components/Tabs/components/TruncateText/truncate-text";
import { Container, FirstRow, SecondRow } from "./styles";

interface EmployeeDetailsProps {
  employeeDetails: {
    name: string;
    documentNumber: string;
    status: EmployeeStatus;
  };
}

export const EmployeeDetails = ({ employeeDetails }: EmployeeDetailsProps) => {
  return (
    <Container>
      <FirstRow>
        <Typography.Body4 weight={600} color={"neutral.20"}>
          <TruncateText text={employeeDetails.name} limit={23} />
        </Typography.Body4>
        <StatusTagV2 status={employeeDetails.status} />
      </FirstRow>
      <SecondRow>
        <Typography.Body4 weight={400} color={"neutral.20"}>
          {cpfFormater(employeeDetails.documentNumber)}
        </Typography.Body4>
      </SecondRow>
    </Container>
  );
};
