import { Icons, Menu, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Action } from "src/pages/PagePermissionsV2/components/Tabs/types";
import { useTheme } from "styled-components";
import { Container, IconContainer, MenuOption } from "./styles";

interface SuperAdminsMenuProps {
  actions: Action[];
  isHovered: boolean;
}
export const SuperAdminsMenu = ({
  actions,
  isHovered,
}: SuperAdminsMenuProps) => {
  const theme = useTheme();
  return (
    <Container $hovered={isHovered}>
      <Menu
        type={"default"}
        options={actions.map((option) => ({
          key: option.key,
          onClick: option.onClick,
          children: (
            <MenuOption
              color={
                option.key === "delete"
                  ? theme.colors.status.negative[50]
                  : "transparent"
              }
            >
              {option.icon && (
                <Icons
                  name={option.icon}
                  fill="transparent"
                  color={
                    option.key === "delete"
                      ? theme.colors.status.negative[10]
                      : theme.colors.neutral[30]
                  }
                />
              )}
              {option.label && (
                <Typography.Body3
                  color={
                    option.key === "delete"
                      ? "status.negative.10"
                      : "neutral.30"
                  }
                >
                  {option.label}
                </Typography.Body3>
              )}
            </MenuOption>
          ),
        }))}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 50, horizontal: -150 }}
      >
        <IconContainer>
          <Icons size={16} name="IconDotsVertical" onClick={() => null} />
        </IconContainer>
      </Menu>
    </Container>
  );
};
