import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { RoleCard } from "./styles";

interface RoleBasicInfoProps {
  role: { name?: string; description?: string };
}

export const RoleBasicInfo = ({ role }: RoleBasicInfoProps) => {
  return (
    <RoleCard>
      <Typography.Caption weight={700} color="neutral.50">
        Nome da permissão
      </Typography.Caption>
      <Typography.Body3 weight={400} color="neutral.20">
        {role?.name}
      </Typography.Body3>
      <Typography.Caption weight={700} color="neutral.50">
        Descrição da permissão
      </Typography.Caption>
      <Typography.Body3 weight={400} color="neutral.20">
        {role?.description || "-"}
      </Typography.Body3>
    </RoleCard>
  );
};
