import { PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50vh;
  padding: ${({ theme }) => theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.spacings.xs3};
  background-color: ${({ theme }) => theme.colors.secondary[99]};
  border: 1px solid ${({ theme }) => theme.colors.secondary[95]};
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledPillButton = styled(PillButton)`
  display: flex;
`;
