import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { MembersCard, MembersContent } from "./styles";

interface RoleMembersSummaryProps {
  members: string[];
}

export const RoleMembersSummary = ({ members }: RoleMembersSummaryProps) => {
  if (members.length === 0) {
    return <>-</>;
  }
  return (
    <MembersCard>
      <Typography.Caption color="neutral.40">
        Pessoas selecionadas
      </Typography.Caption>
      <MembersContent>
        {members.map((member) => (
          <Tag variant="primary" size="small">
            {member}
          </Tag>
        ))}
      </MembersContent>
    </MembersCard>
  );
};
