import { EmptyStateType } from "@components/EmployeesTable/components/EmptyState/employees-table-empty-state";
import { EmployeesTable } from "@components/index";
import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { setEventTracking } from "@utils/eventTracking";
import { useMemo, useState } from "react";
import { Role } from "src/types";
import { PermissionsTable } from "./components";
import { Container } from "./styles";
import { Employee, PaginationMetadata, SelectedCompany } from "./types";

enum Tabs {
  ROLES = 0,
  EMPLOYEES = 1,
}

type tabItem = {
  label: string;
  component: JSX.Element;
};

interface PermissionsTabsProps {
  selectedCompany: SelectedCompany;
  permissions?: Role[];
  employeesData?: { employees?: Employee[]; metadata?: PaginationMetadata };
  isLoading: boolean;
}

export const PermissionsTabs = ({
  selectedCompany,
  permissions,
  employeesData,
  isLoading,
}: PermissionsTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.ROLES);

  const handleSelect = (e: Tabs) => {
    if (e === Tabs.EMPLOYEES) {
      setEventTracking("permissions_page_all_members_tab_clicked");
    }
    setSelectedTab(e);
  };
  const permissionLabel = useMemo(() => {
    return permissions && permissions?.length > 0
      ? `Perfis de permissão (${permissions?.length})`
      : `Perfis de permissão`;
  }, [permissions]);

  const employeesLabel = useMemo(() => {
    return employeesData?.metadata && employeesData?.metadata.totalCount > 0
      ? `Todos os membros (${employeesData?.metadata?.totalCount})`
      : `Todos os membros`;
  }, [employeesData?.metadata]);
  const tabItems: tabItem[] = [
    {
      label: permissionLabel,
      component: (
        <PermissionsTable
          permissions={permissions || []}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: employeesLabel,
      component: (
        <EmployeesTable
          companyId={selectedCompany.id}
          emptyStateType={EmptyStateType.EMPLOYEES}
        />
      ),
    },
  ];

  return (
    <Container className="page_permissions_tabs">
      <Tab
        selected={selectedTab}
        onTabChanged={(e) => {
          handleSelect(e);
        }}
        tabItens={tabItems}
      />
    </Container>
  );
};
