import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 96px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
  padding: 16px;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 12px;
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
`;

export const BannerDescription = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 4px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 200px;
  margin: auto;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
`;
