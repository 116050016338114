import { WarningModal } from "@components/WarningModal/warning-modal";
import {
  SearchField,
  ShapeIconProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useDebouncedValue } from "@utils/useDebouncedValue";
import { FormikProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormValues } from "src/pages/PageRoleCreate/create-role-context";
import { routesV2 } from "src/routes";
import { Role, RoleModule } from "src/types";
import { ChoosePermissionsEmptyState } from "./components/EmptyState/choose-permissions-empty-state";
import { ModuleWrapper } from "./components/ModuleWrapper/module-wrapper";
import { Container, HeaderContainer, PermissionsContainer } from "./styles";

const SEARCH_INPUT_DELAY = 300;

export type SelectOption = {
  key: string;
  icon: ShapeIconProps["name"];
  title: string;
  description: string;
};

interface ChoosePermissionsProps {
  modules?: RoleModule[] | [];
  equalRole?: Role;
  equalRoleBypass: boolean;
  setEqualRole: (role?: Role) => void;
  setEqualRoleBypass: (value: boolean) => void;
  formik: FormikProps<FormValues>;
}

export const ChoosePermissionsStep = ({
  modules,
  equalRole,
  equalRoleBypass,
  setEqualRole,
  setEqualRoleBypass,
  formik,
}: ChoosePermissionsProps) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const searchQuery = useDebouncedValue(search, SEARCH_INPUT_DELAY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedOption, setSelectOption] = useState<SelectOption>({
    key: "continue",
    title: `Continuar e criar uma nova permissão`,
    description: "Crie uma nova permissão para outro fim específico.",
    icon: "IconPlus",
  });

  useEffect(() => {
    if (equalRole && !equalRoleBypass) setIsModalOpen(true);
  }, [equalRole]);

  const useFilteredModules = (modules: RoleModule[], searchQuery: string) => {
    const normalize = (str: string) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "");

    return useMemo(() => {
      if (!searchQuery) return modules;
      const normalizedQuery = normalize(searchQuery);

      return modules
        ?.map((module) => ({
          ...module,
          features: module.features
            .map((feature) => ({
              ...feature,
              permissions: feature.permissions.filter((permission) =>
                normalize(permission.name).includes(normalizedQuery)
              ),
            }))
            .filter(
              (feature) =>
                normalize(feature.name).includes(normalizedQuery) ||
                feature.permissions.length > 0
            ),
        }))
        .filter(
          (module) =>
            normalize(module.name).includes(normalizedQuery) ||
            module.features.length > 0
        );
    }, [modules, searchQuery]);
  };

  const filteredModules = useFilteredModules(modules || [], searchQuery);

  return (
    <Container>
      <HeaderContainer>
        <Typography.Headline8 color="neutral.20">
          {" "}
          Selecione os níveis de permissão
        </Typography.Headline8>
        <Typography.Body4 color="neutral.30">
          Defina abaixo os níveis de permissão para os módulos e áreas de cada
          produto contratado pela sua empresa.
        </Typography.Body4>
        <SearchField
          label="Buscar áreas e permissões"
          value={searchQuery}
          onChange={(e) => setSearch(e.target.value)}
        />
      </HeaderContainer>
      <PermissionsContainer>
        {filteredModules?.length ? (
          filteredModules
            ?.filter((module) => module.features.length)
            .map((permissionModule, index) => (
              <ModuleWrapper
                key={`${permissionModule.id}_${index}`}
                module={permissionModule}
                searchQuery={searchQuery}
                formik={formik}
              />
            ))
        ) : (
          <ChoosePermissionsEmptyState />
        )}
      </PermissionsContainer>
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", variant: "error", size: 32 }}
        modalSize="xs"
        isOpen={isModalOpen}
        title="Já existe um perfil de permissão com os mesmos critérios selecionados"
        description={`Os critérios selecionados correspondem ao perfil "${equalRole?.name}". Adicione pessoas ao perfil existente ou crie um novo (não recomendado). Escolha a ação:`}
        footerConfig={{
          text: "Continuar",
          iconColor: "pink",
          buttonSize: "medium",
        }}
        selectOptions={[
          {
            key: "role",
            title: `Visualizar perfil de permissão "${equalRole?.name}"`,
            description:
              "Acesse o perfil de permissão já existente e adicione novas pessoas.",
            icon: "IconUsersGroup",
          },
          {
            key: "continue",
            title: `Continuar e criar uma nova permissão`,
            description: "Crie uma nova permissão para outro fim específico",
            icon: "IconPlus",
          },
        ]}
        selectedOption={selectedOption}
        onSelectOption={setSelectOption}
        onClose={() => {
          setIsModalOpen(false);
          setEqualRole(undefined);
        }}
        onSubmit={() => {
          if (selectedOption.key === "continue") {
            setEqualRoleBypass(true);
            setIsModalOpen(false);
          } else {
            setIsModalOpen(false);
            equalRole
              ? navigate(
                  routesV2.pageRolesPermissions.replace(
                    ":roleId",
                    equalRole?.id
                  )
                )
              : navigate(routesV2.pageRoles);
          }
        }}
      />
    </Container>
  );
};
