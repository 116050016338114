import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";
import {
  PageBannerContainer,
  PageHeaderContainer,
  PageHeaderText,
  PageTableContainer,
  PageTabsContainer,
} from "./styles";

export const PagePermissionsSkeleton = () => {
  const { isAdmin } = usePermissions();
  return (
    <PageContainer style={{ gap: "12px" }}>
      <PageHeaderContainer>
        <PageHeaderText>
          <Skeleton width={"250px"} height={"20px"} variant="text" />
          <Skeleton width={"200px"} height={"50px"} variant="text" />
          <Skeleton width={"300px"} height={"20px"} variant="text" />
        </PageHeaderText>
        <Skeleton
          width={"300px"}
          height={"40px"}
          style={{ marginTop: "60px" }}
          variant="rounded"
        />
      </PageHeaderContainer>
      {isAdmin ? (
        <PageBannerContainer>
          <Skeleton width={"100%"} height={"100px"} variant="rounded" />
        </PageBannerContainer>
      ) : (
        <div style={{ marginTop: "20px" }}></div>
      )}
      <PageTableContainer>
        <PageTabsContainer>
          <Skeleton width={"200px"} height={"20px"} variant="text" />
          <Skeleton width={"200px"} height={"20px"} variant="text" />
        </PageTabsContainer>
        <Skeleton width={"100%"} height={"500px"} variant="rounded" />
      </PageTableContainer>
    </PageContainer>
  );
};
