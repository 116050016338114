import { trpc } from "@api/client";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback } from "react";
import { useUnsetAdministrator } from "src/pages/PageGlobalAdmins/queries";
import { useDeleteEmployeeFromRoleMutation } from "src/pages/PagePermissions/queries";

export const useDeleteActions = () => {
  const utils = trpc.useContext();
  const { selectedCompany } = useSelectedCompany();
  const { isAdmin } = usePermissions();
  const { mutate: removeEmployeeFromRole } = useDeleteEmployeeFromRoleMutation({
    companyId: selectedCompany.id,
  });
  const { fetch: removeSuperAdmin } = useUnsetAdministrator();

  const deleteSuperAdmin = useCallback(
    async (employeeId: string) => {
      try {
        await removeSuperAdmin({ employeeId });
        await utils.getAdministratorsIds.cancel();
        utils.getAdministratorsIds.setData(undefined, (prev) => {
          if (!prev) return prev;
          return prev.filter((id) => id !== employeeId);
        });
        await utils.client.getEmployeesTable.mutate({
          companyId: selectedCompany.id!,
          ...(isAdmin && { restrictToCompany: false }),
          page: 1,
          pageSize: 10,
        });
        dispatchToast({
          content: "Superadministrador removido com sucesso!",
          type: "success",
        });
      } catch {
        dispatchToast({
          content: "Erro ao remover superadministrador",
          description:
            "Verifique seus perfis de permissão, tente novamente mais tarde ou entre em contato com a nossa Central de Ajuda.",
          type: "error",
        });
      }
    },
    [removeSuperAdmin, selectedCompany, isAdmin]
  );

  const deleteEmployeeFromRole = useCallback(
    async ({
      employee,
      role,
    }: {
      employee: { id: string; name: string };
      role: { id: string; name: string };
    }) => {
      await removeEmployeeFromRole({ employee, role });
      await utils.getEmployeesIdsByRole.setData({ roleId: role.id }, (prev) => {
        if (!prev) return;
        return prev.filter((id) => id !== employee.id);
      });
      await utils.client.getEmployeesTable.mutate({
        companyId: selectedCompany.id!,
        ...(isAdmin && { restrictToCompany: false }),
        page: 1,
        pageSize: 10,
      });
    },
    [removeEmployeeFromRole]
  );

  return { deleteSuperAdmin, deleteEmployeeFromRole };
};
