import { SelectOption } from "@components/ChoosePermissions/choose-permissions-step";
import {
  ButtonProps,
  Checkbox,
  IconsProps,
  Modal,
  PillButton,
  Radio,
  ShapeIcon,
  ShapeIconProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FC, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import {
  AlertWrapper,
  AuditWrapper,
  ConfirmInput,
  ConfirmWordWrapper,
  Container,
  FooterCancelButton,
  FooterContainer,
  FooterSubmitButton,
  Header,
  OptionCard,
  OptionCardTextWrapper,
  TextWrapper,
} from "./styles";

type ModalSize = "xs" | "sm" | "md" | "full";
type ButtonSize = "small" | "extra-small" | "medium" | "large";

interface IconConfig extends Pick<ShapeIconProps, "name" | "variant"> {
  size: number;
}

interface WarningModalProps {
  isOpen: boolean;
  iconConfig: IconConfig;
  title: string;
  description: string;
  confirmWord?: string;
  auditConfirm?: string;
  selectOptions?: SelectOption[];
  footerConfig: {
    text: string;
    buttonSize: ButtonSize;
    variant?: ButtonProps["variant"];
    icon?: IconsProps["name"];
    iconColor: "negative" | "pink";
    loading?: boolean;
  };
  selectedOption?: SelectOption;
  modalSize?: ModalSize;
  onClose: () => void;
  onSubmit: () => void;
  onSelectOption?: (option: SelectOption) => void;
}

export const WarningModal: FC<WarningModalProps> = ({
  isOpen,
  iconConfig,
  title,
  description,
  confirmWord,
  auditConfirm,
  selectOptions,
  footerConfig,
  modalSize = "md",
  selectedOption,
  onClose,
  onSubmit,
  onSelectOption,
}) => {
  const theme = useTheme();
  const [inputWord, setInputWord] = useState("");
  const [actionConfirm, setActionConfirm] = useState(false);

  const normalizedConfirmWord = confirmWord?.toUpperCase();
  const hasSelectionOptions = selectOptions && selectOptions.length > 0;

  const submitEnabled = useMemo(() => {
    const wordMatch = confirmWord ? inputWord === normalizedConfirmWord : true;
    const auditMatch = auditConfirm ? actionConfirm : true;
    return wordMatch && auditMatch;
  }, [confirmWord, inputWord, auditConfirm, actionConfirm]);

  return (
    <Modal.Root
      open={isOpen}
      size={modalSize}
      onClose={onClose}
      footer={
        <ModalFooter
          onCancel={onClose}
          onSubmit={onSubmit}
          submitEnabled={submitEnabled}
          {...footerConfig}
        />
      }
    >
      <Container>
        <ModalHeader iconConfig={iconConfig} onClose={onClose} theme={theme} />

        <TextWrapper>
          <Typography.Headline8 color="neutral.10">
            {title}
          </Typography.Headline8>
          <Typography.Body4 color="neutral.40">{description}</Typography.Body4>
        </TextWrapper>

        {auditConfirm && (
          <AuditConfirmation
            label={auditConfirm}
            checked={actionConfirm}
            onChange={setActionConfirm}
          />
        )}

        {confirmWord && (
          <ConfirmWordInput
            expectedWord={normalizedConfirmWord}
            currentWord={inputWord}
            onWordChange={setInputWord}
          />
        )}

        {hasSelectionOptions && onSelectOption && (
          <SelectionOptions
            options={selectOptions}
            selectedKey={selectedOption?.key}
            onSelect={onSelectOption}
            theme={theme}
          />
        )}
      </Container>
    </Modal.Root>
  );
};

const ModalHeader: FC<{
  iconConfig: IconConfig;
  onClose: () => void;
  theme: any;
}> = ({ iconConfig, onClose, theme }) => (
  <Header>
    <AlertWrapper>
      <ShapeIcon {...iconConfig} color={theme.colors.status.negative[50]} />
      <Typography.Body4 weight={700} color="status.negative.50">
        Atenção!
      </Typography.Body4>
    </AlertWrapper>
    <PillButton
      icon="IconX"
      variant="default"
      type="secondary"
      size="small"
      onClick={onClose}
    />
  </Header>
);

const AuditConfirmation: FC<{
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}> = ({ label, checked, onChange }) => (
  <AuditWrapper>
    <Checkbox
      color="default"
      checked={checked}
      onChange={() => onChange(!checked)}
    />
    <Typography.Body3 color="neutral.30">{label}</Typography.Body3>
  </AuditWrapper>
);

const ConfirmWordInput: FC<{
  expectedWord?: string;
  currentWord: string;
  onWordChange: (value: string) => void;
}> = ({ expectedWord, currentWord, onWordChange }) => (
  <ConfirmWordWrapper>
    <ConfirmInput
      label={`Digite ${expectedWord} e confirme`}
      value={currentWord}
      onChange={(e) => onWordChange(e.target.value)}
    />
  </ConfirmWordWrapper>
);

const SelectionOptions: FC<{
  options: SelectOption[];
  selectedKey?: string;
  onSelect: (option: SelectOption) => void;
  theme: any;
}> = ({ options, selectedKey, onSelect, theme }) => (
  <>
    {options.map((option) => (
      <OptionCard
        key={option.key}
        $selected={selectedKey === option.key}
        role="button"
        tabIndex={0}
        onClick={() => onSelect(option)}
      >
        <ShapeIcon
          variant="default"
          name={option.icon}
          size={32}
          color={theme.colors.secondary[50]}
        />
        <OptionCardTextWrapper>
          <Typography.Body4 weight={700} color="neutral.20">
            {option.title}
          </Typography.Body4>
          <Typography.Caption color="neutral.40">
            {option.description}
          </Typography.Caption>
        </OptionCardTextWrapper>
        <Radio
          checked={selectedKey === option.key}
          size="small"
          color="primary"
          onChange={() => onSelect(option)}
        />
      </OptionCard>
    ))}
  </>
);

const ModalFooter: FC<{
  onCancel: () => void;
  onSubmit: () => void;
  submitEnabled: boolean;
  text: string;
  buttonSize: ButtonSize;
  icon?: IconsProps["name"];
  iconColor: "negative" | "pink";
  loading?: boolean;
}> = ({
  onCancel,
  onSubmit,
  submitEnabled,
  text,
  buttonSize,
  icon,
  iconColor,
  loading,
}) => (
  <FooterContainer>
    <FooterCancelButton onClick={onCancel}>Cancelar</FooterCancelButton>
    <FooterSubmitButton $enabled={submitEnabled} $color={iconColor}>
      <PillButton
        disabled={!submitEnabled}
        variant="default"
        type="primary"
        size={buttonSize}
        onClick={onSubmit}
        loading={loading}
        label={text}
        icon={icon}
        iconPosition={icon ? "right" : undefined}
      />
    </FooterSubmitButton>
  </FooterContainer>
);
