interface TruncateTextProps {
  text?: string;
  limit: number;
}

export const TruncateText = ({ text, limit = 21 }: TruncateTextProps) => {
  if (text) {
    const truncatedText =
      text.length > limit ? text.slice(0, limit) + "..." : text;
    return <>{truncatedText}</>;
  }
  return <>-</>;
};
