import { useEffect, useRef, useState } from "react";

export const useStableHover = () => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverRef = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const node = hoverRef.current;
    if (!node) return;

    const handleEnter = () => {
      clearTimeout(timeout.current!);
      setIsHovered(true);
    };

    const handleLeave = () => {
      timeout.current = setTimeout(() => setIsHovered(false), 500);
    };

    node.addEventListener("mouseenter", handleEnter);
    node.addEventListener("mouseleave", handleLeave);

    return () => {
      node.removeEventListener("mouseenter", handleEnter);
      node.removeEventListener("mouseleave", handleLeave);
    };
  }, []);

  return { containerRef: hoverRef, isHovered };
};
