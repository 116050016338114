import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  PageHeaderContainer,
  PageHeaderText,
  PageTableContainer,
  PageTabsContainer,
} from "./styles";

export const PageRoleSkeleton = () => {
  return (
    <PageContainer style={{ gap: "12px" }}>
      <PageHeaderContainer>
        <PageHeaderText>
          <Skeleton width={"350px"} height={"20px"} variant="text" />
          <Skeleton width={"300px"} height={"50px"} variant="text" />
        </PageHeaderText>
      </PageHeaderContainer>
      <PageTableContainer>
        <PageTabsContainer>
          <Skeleton width={"200px"} height={"20px"} variant="text" />
          <Skeleton width={"200px"} height={"20px"} variant="text" />
        </PageTabsContainer>
        <Skeleton width={"100%"} height={"500px"} variant="rounded" />
      </PageTableContainer>
    </PageContainer>
  );
};
