import { CustomStepper } from "@components/CustomStepper/custom-stepper";
import { ModalOTP, useModalOTPActions } from "@components/ModalOTP";
import { handleTipClick, Tips } from "@components/Tips/tips";
import {
  Button,
  Focused,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routesV2 } from "src/routes";
import { BasicInfoStep } from "../../components/BasicInfo/basic-info-step";
import { ChooseMembersStep } from "../../components/ChooseMembers/choose-members-step";
import { ChoosePermissionsStep } from "../../components/ChoosePermissions/choose-permissions-step";
import { FeedbackStep } from "../../components/Feedback/feedback-step";
import { ReviewStep } from "../../components/Review/review-step";

import { EDIT_ROLE_STEPS } from "./edit-role-context";
import { useEditRole } from "./hooks/useEditRole";
import { Container, LeftContainer, RightContainer } from "./styles";

export const PageRoleEdit = () => {
  const otpRef = useModalOTPActions();
  const {
    formik,
    modules,
    equalRole,
    setEqualRole,
    setEqualRoleBypass,
    step,
    goBack,
    goForward,
    editRole,
    isLoading,
    members,
    setMembers,
    roleId,
    setStep,
  } = useEditRole();
  const navigate = useNavigate();
  const isFeedbackStep = step === EDIT_ROLE_STEPS.FEEDBACK;

  const steps = [
    {
      title: "Informações básicas",
      description:
        "Forneça as informações básicas como nome e descrição do perfil de permissão",
    },
    {
      title: "Níveis de permissão",
      description:
        "Selecione quais áreas as pessoas adicionadas a essa permissão  poderão visualizar ou gerenciar",
    },
    {
      title: "Membros da permissão",
      description: "Inclua as pessoas como membros desta permissão",
    },
    {
      title: "Revisão",
      description:
        "Confira a prévia da sua permissão e revise antes de editá-la",
    },
  ];

  const tips = [
    <Typography.Body4 weight={600} color="neutral.30">
      1. Você pode adicionar membros desta empresa ou de todo o grupo econômico
      a uma permissão usando a lista ao lado.
    </Typography.Body4>,
    <Typography.Body4 weight={600} color="neutral.30">
      2. Não encontrou uma pessoa na lista? Para adicionar um membro a uma
      permissão, ele precisa estar cadastrado na plataforma.
      <LinkButton variant="default" onClick={() => handleTipClick(navigate)}>
        {" "}
        Envie um convite de acesso
      </LinkButton>
      para que essa pessoa crie sua conta e já entre com a nova permissão
      atribuída.
    </Typography.Body4>,
  ];

  const footerActions = useMemo(() => {
    if (isFeedbackStep) return { startActions: [], endActions: [] };
    const startActions = [
      <LinkButton
        variant="neutral"
        key="cancel"
        onClick={() => navigate(routesV2.pageRoles)}
      >
        Cancelar
      </LinkButton>,
    ];

    const endActions = [];

    if (step !== EDIT_ROLE_STEPS.BASIC_INFO) {
      endActions.push(
        <Button variant="secondary" size="medium" key="back" onClick={goBack}>
          <Icons name="IconArrowLeft" fill="transparent" /> Voltar
        </Button>
      );
    }

    if (step === EDIT_ROLE_STEPS.REVIEW) {
      endActions.push(
        <Button
          variant="primary"
          size="medium"
          key="create"
          onClick={async () => {
            otpRef.current?.openModalIfNeeded({
              onConfirmCallback: () => {
                editRole();
              },
            });
          }}
          loading={isLoading}
        >
          Editar perfil de permissão{" "}
          <Icons name="IconCheck" fill="transparent" />
        </Button>
      );
    } else {
      endActions.push(
        <Button variant="primary" size="medium" key="next" onClick={goForward}>
          Continuar <Icons name="IconArrowRight" fill="transparent" />
        </Button>
      );
    }

    return { startActions, endActions };
  }, [step, isFeedbackStep, goBack, goForward, editRole, isLoading, navigate]);

  return (
    <Focused
      hasHeader
      containerStyle={{
        // Workaround for hiding menu
        // until refactor
        position: "fixed",
        width: "100%",
        background: "#fff",
        top: 0,
        left: 0,
      }}
      title="Editar perfil de permissão"
      routes={[
        {
          label: "< Voltar para Perfis de Permissão",
          route: `${routesV2.pageRoles}`,
        },
      ]}
      footer={footerActions}
    >
      <Container>
        {!isFeedbackStep ? (
          <>
            <LeftContainer>
              {step !== EDIT_ROLE_STEPS.FEEDBACK ? (
                <CustomStepper steps={steps} activeStep={step} />
              ) : null}
              {step === EDIT_ROLE_STEPS.MEMBERS && (
                <Tips title={"Dicas"} tips={tips} />
              )}
            </LeftContainer>
            <RightContainer>
              {step === EDIT_ROLE_STEPS.BASIC_INFO && (
                <BasicInfoStep formik={formik} />
              )}
              {step === EDIT_ROLE_STEPS.PERMISSIONS && (
                <ChoosePermissionsStep
                  equalRole={equalRole}
                  equalRoleBypass={true}
                  modules={modules}
                  setEqualRole={setEqualRole}
                  setEqualRoleBypass={setEqualRoleBypass}
                  formik={formik}
                />
              )}
              {step === EDIT_ROLE_STEPS.MEMBERS && (
                <ChooseMembersStep members={members} setMembers={setMembers} />
              )}
              {step === EDIT_ROLE_STEPS.REVIEW && (
                <ReviewStep
                  formik={formik}
                  members={members}
                  modules={modules}
                  setStep={setStep}
                  edit={true}
                />
              )}
            </RightContainer>
          </>
        ) : (
          <FeedbackStep roleId={roleId} edit={true} />
        )}
      </Container>
      <ModalOTP
        ref={otpRef}
        subtitle="Para a segurança dos dados sensíveis da sua empresa, você precisa confirmar sua identidade para editar perfil de permissão."
      />
    </Focused>
  );
};
