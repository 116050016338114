import {
  Icons,
  LinkButton,
  Modal,
  OTPField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  getAuthenticatedUser,
  useAdminMfaManager,
} from "@flash-tecnologia/hros-web-utility";
import { useFormik } from "formik";
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import * as yup from "yup";
import { Countdown } from "./components/Countdown";
import { Footer } from "./components/Footer";
import { FallbackButtons, StyledShapeIcon, TextWrapper } from "./styled";
import { ModalOTPInterface } from "./types";

type OnConfirmCallback = (confirmationCode: string) => void;
type OnCancelCallback = () => void;

type ModalOTPModalActions = {
  openModalIfNeeded: (callbacks: {
    onConfirmCallback?: OnConfirmCallback;
    onCancelCallback?: OnCancelCallback;
  }) => void;
};

export const useModalOTPActions = () => {
  return useRef<ModalOTPModalActions>(null);
};

export const ModalOTP = forwardRef(
  (props: ModalOTPInterface, ref: ForwardedRef<ModalOTPModalActions>) => {
    // hooks
    const {
      timeRemaining,
      sendCode,
      verifyCode,
      loadingSendCode,
      loadingVerifyCode,
      getAuthenticatedCode,
    } = useAdminMfaManager();

    //refs
    const onConfirmCallbackRef = useRef<OnConfirmCallback | undefined>();
    const onCancelCallbackRef = useRef<OnCancelCallback | undefined>();

    // states
    const [email, setEmail] = useState<string | undefined>();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [modalTimer, setModalTimer] = useState<number>(timeRemaining());
    const [showCountdown, setShowCountdown] = useState<boolean>(true);
    const [otpHasError, setOTPHasError] = useState<boolean>(false);

    const validationSchema = yup.object({
      otp: yup.string().min(6).required("Favor preencher campos!"),
    });

    const form = useFormik({
      initialValues: {
        otp: "",
      },
      validationSchema: validationSchema,
      onSubmit: async (value) => {
        handleVerifyCode(value.otp);
      },
      isInitialValid: false,
    });

    useImperativeHandle(
      ref,
      () => ({
        openModalIfNeeded: (callbacks: {
          onConfirmCallback?: OnConfirmCallback;
          onCancelCallback?: OnCancelCallback;
        }) => {
          onConfirmCallbackRef.current = callbacks.onConfirmCallback;
          onCancelCallbackRef.current = callbacks.onCancelCallback;
          openModalIfNeeded();
        },
      }),
      []
    );

    const openModalIfNeeded = async () => {
      const authCode = await getAuthenticatedCode();
      if (!authCode) {
        const authenticatedUser = await getAuthenticatedUser();
        setEmail(authenticatedUser?.email);
        sendCode();
        setModalTimer(timeRemaining());
        setShowCountdown(timeRemaining() > 0);
        setModalIsOpen(true);
        return;
      }

      onConfirmCallbackRef.current?.(authCode);
      setModalIsOpen(false);
    };

    const handleCloseModal = () => {
      onCancelCallbackRef.current?.();
      setModalIsOpen(false);
    };

    const handleVerifyCode = async (code: string) => {
      const success = await verifyCode(code);
      setOTPHasError(!success);
      if (success) {
        setModalIsOpen(false);
        onConfirmCallbackRef.current?.(code);
      }
    };

    const handleSendCode = () => {
      sendCode();
      setShowCountdown(true);
    };

    const handleFinishCountDown = () => {
      setShowCountdown(false);
    };

    const maskedEmail = useMemo(
      () =>
        email?.replace(
          /(.*)(.{3})(?=@)/,
          (_, p1, p2) => `${p1.replace(/./g, "•")}${p2}`
        ),
      [email]
    );

    return (
      <Modal.Root open={modalIsOpen} onClose={handleCloseModal} size="sm">
        <>
          <Modal.Content>
            <TextWrapper>
              <StyledShapeIcon
                variant="default"
                name="IconInfoCircle"
                size={64}
                color="var(--color-primary)"
              />
              <Typography variant="body3" weight={700} color={"flamingo-pink"}>
                Atenção!
              </Typography>
              <Typography variant="headline6" weight={700}>
                Confirme o código de acesso enviado para o seu e-mail
              </Typography>
              <Typography variant="body3" weight={400} color={"neutral.50"}>
                {props.subtitle}
              </Typography>
              <Typography variant="body3" weight={600} color={"neutral.40"}>
                Insira o código de confirmação recebido
              </Typography>
              <OTPField
                numInputs={6}
                error={otpHasError}
                messageError="Insira um código válido"
                onChange={(e: any) =>
                  form.handleChange({ target: { id: "otp", value: e } })
                }
              />
              <FallbackButtons>
                <Typography variant="body3" weight={600}>
                  Enviamos seu código por e-mail para {maskedEmail}
                </Typography>
                {/* <LinkButton variant="primary">
                  Editar
                  <Icons name="IconPencil" color="var(--color-primary)"></Icons>
                </LinkButton> */}
              </FallbackButtons>
              <FallbackButtons>
                {showCountdown && modalTimer ? (
                  <Typography variant="body3">
                    Aguarde o recebimento • {""}
                    <Countdown
                      time={modalTimer}
                      onFinished={handleFinishCountDown}
                    />
                  </Typography>
                ) : (
                  <Typography variant="body3">
                    Não recebeu seu código?
                  </Typography>
                )}
                <LinkButton
                  variant="default"
                  disabled={showCountdown || loadingSendCode}
                  onClick={handleSendCode}
                >
                  Reenviar <Icons name="IconArrowBack" />
                </LinkButton>
              </FallbackButtons>
            </TextWrapper>
          </Modal.Content>
          <Modal.Footer>
            <Footer
              disabled={!form.isValid}
              loading={loadingVerifyCode}
              onClose={handleCloseModal}
              onSubmit={form.handleSubmit}
            ></Footer>
          </Modal.Footer>
        </>
      </Modal.Root>
    );
  }
);
