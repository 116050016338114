import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { setEventTracking } from "@utils/eventTracking";
import { NavigateFunction } from "react-router-dom";
import { TipContainer, TipListContainer, TipTitle } from "./styles";

interface TipsProps {
  title: string;
  tips: JSX.Element[];
}

export const handleTipClick = (navigate: NavigateFunction) => {
  setEventTracking("create_employee_invite_tip_clicked");
  navigate("/employees");
};

export const Tips = ({ title, tips }: TipsProps) => {
  return (
    <TipContainer>
      <TipTitle>
        <ShapeIcon variant="disabled" size={24} name={"IconBulb"} />
        <Typography.Body4 weight={700} color="neutral.30">
          {title}
        </Typography.Body4>
      </TipTitle>
      <TipListContainer>{tips.map((tip) => tip)}</TipListContainer>
    </TipContainer>
  );
};
