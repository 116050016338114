import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-top: ${({ theme }) => theme.spacings.s};
  padding-bottom: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.m};
`;

export const SectionTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const WarningTip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color ${({ theme }) => theme.colors.status.attention[90]};
  padding: 8px 12px 8px 12px;
  gap: ${({ theme }) => theme.spacings.xs4};
  border-radius: 10px;
`;
