import { SetRoleModal } from "@components/SetRoleModal/set-role-modal";
import {
  IconsProps,
  PillButton,
  Table,
  tableControllers,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";
import { useDebouncedValue } from "@utils/useDebouncedValue";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TruncateText } from "../../pages/PagePermissionsV2/components/Tabs/components/TruncateText/truncate-text";
import { EmployeeDetails } from "./components/EmployeeDetails/employee-details";
import {
  EmployeesTableEmptyState,
  EmptyStateType,
} from "./components/EmptyState/employees-table-empty-state";
import { TagsColumn } from "./components/TagsColumn/tags-column";
import { useEmployeesTable } from "./hooks/useEmployeesTable";
import { CustomPillButton, TableContainer } from "./styles";

export type BulkAction = {
  key: string;
  label: string;
  icon: IconsProps["name"];
  onClick: (selected: any[]) => void;
};

interface EmployeesTableProps {
  companyId: string;
  employeeIds?: string[];
  CustomActions?(__0: {
    employeeName: string;
    employeeId: string;
  }): JSX.Element;
  emptyStateType: EmptyStateType;
  superAdmins?: boolean;
  bulkActions?: BulkAction[];
}

const TWO_SECONDS = 2000;
export const EmployeesTable = ({
  companyId,
  employeeIds,
  CustomActions,
  emptyStateType,
  superAdmins,
  bulkActions,
}: EmployeesTableProps) => {
  const { isAdmin } = usePermissions();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<{
    employeeId: string;
    employeeName: string;
    employeeRoles: { id: string; name: string }[];
  }>({ employeeId: "", employeeName: "", employeeRoles: [] });
  const [refresh, setRefresh] = useState<boolean>(false);
  const delayedRefresh = useDebouncedValue(refresh, TWO_SECONDS);

  const {
    data: employeesData,
    fetchEmployees,
    isLoading: isLoadingEmployees,
    pagination,
    pageSizeOptions,
    setPagination,
    searchQuery,
    setSearch,
  } = useEmployeesTable();

  useEffect(() => {
    fetchEmployees({
      companyId: companyId!,
      ...(employeeIds && { employeeIds }),
      ...(isAdmin && { restrictToCompany: false }),
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      searchQuery: searchQuery,
    });
  }, [pagination, searchQuery, companyId, employeeIds, delayedRefresh]);

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 1 });
  }, [searchQuery]);

  const tableColumns = useMemo(() => {
    const baseColumns = [
      {
        header: "Pessoa",
        accessorKey: "name",
        size: 300,
        cell: ({ row }: any) => (
          <EmployeeDetails
            employeeDetails={{
              name: row?.original?.name,
              documentNumber: row?.original?.documentNumber,
              status: row?.original?.status,
            }}
          />
        ),
      },
      {
        header: "Cargo",
        accessorKey: "role",
        size: 100,
        cell: ({ row }: any) => (
          <Typography.Body4 weight={600} color="neutral.20">
            <TruncateText text={row?.original?.role} limit={26} />
          </Typography.Body4>
        ),
      },
      {
        header: "Departamentos",
        accessorKey: "departments",
        size: 150,
        cell: ({ row }: any) => (
          <TagsColumn
            tags={row?.original?.departments}
            emptyLabel="Sem departamento"
          />
        ),
      },
      {
        header: "Perfis de permissão nesta empresa",
        accessorKey: "permissionRoles",
        size: 150,
        cell: ({ row }: any) => (
          <TagsColumn
            tags={row?.original?.permissionRoles}
            emptyLabel="Sem perfil de permissão"
          />
        ),
      },
    ];

    if (superAdmins) {
      baseColumns.pop();
      baseColumns.push({
        header: "Empresa que faz parte",
        accessorKey: "company",
        size: 150,
        cell: ({ row }: any) => (
          <TagsColumn
            tags={[row?.original?.company]}
            tagVariant="gray"
            emptyLabel="Erro ao identificar empresa"
          />
        ),
      });
    }

    if (CustomActions) {
      return [
        ...baseColumns,
        {
          accessorKey: "actions",
          size: 150,
          cell: ({ row }: any) => (
            <CustomActions
              employeeName={row?.original?.name}
              employeeId={row?.original?.id}
            />
          ),
        },
      ];
    }

    return baseColumns;
  }, [CustomActions]);

  const table = tableControllers.useTableColumns<any>({
    total: employeesData?.metadata.totalCount,
    options: {
      selectable: true,
      actions: CustomActions
        ? undefined
        : (context) => [
            {
              label: "Ver perfil da pessoa",
              key: "view",
              icon: "IconEye",
              onClick: () =>
                navigate(`/employees/${context?.row?.original?.id}/profile`),
            },
            {
              label: "Editar perfil de permissão",
              key: "add",
              icon: "IconPencil",
              onClick: () => {
                setSelectedEmployee({
                  employeeId: context?.row?.original?.id,
                  employeeName: context?.row?.original?.name,
                  employeeRoles: context?.row?.original?.permissionRoles,
                });
                setIsModalOpen(true);
              },
            },
          ],
    },
    columns: tableColumns,
    data: (employeesData?.employees as any) || [],
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  const emptyStateConditions =
    !isLoadingEmployees && !employeesData?.employees?.length;

  return (
    <TableContainer>
      <Table.Root variant="modest">
        <Table.Header title="" />
        <Table.Content>
          <Table.FilterSearch
            onSearch={(e) => setSearch(e.target.value)}
          ></Table.FilterSearch>
          {emptyStateConditions ? (
            <EmployeesTableEmptyState
              searchQuery={searchQuery}
              emptyStateType={emptyStateType}
              roleId={roleId!}
            />
          ) : (
            <Table.Grid.Root loading={isLoadingEmployees}>
              <Table.Grid.Header
                getHeaderGroups={table.getHeaderGroups}
                toggleAllRowsExpanded={table.toggleAllRowsExpanded}
              />
              {table.rows.map((row, index) => (
                <Table.Grid.Row key={index + row.id} row={row} />
              ))}
            </Table.Grid.Root>
          )}
          <Table.BulkActions
            open={
              table.selected.allSelected || table.selected.selected.length > 0
            }
            total={employeesData?.metadata.totalCount || 0}
            totalSelected={
              table.selected.allSelected
                ? employeesData?.metadata.totalCount || 0
                : table.selected.selected.length
            }
            onClearSelection={table.resetSelected}
            onSelectAll={() => table.setAllSelected(true)}
          >
            {bulkActions && table.selected.selected ? (
              bulkActions.map((action) => (
                <CustomPillButton key={action.key}>
                  <PillButton
                    label={action.label}
                    icon={action.icon}
                    onClick={() => {
                      action.onClick(
                        table.selected.selected.map((selectedRow) => ({
                          id: selectedRow?.original?.id,
                          name: selectedRow?.original?.name,
                        }))
                      );
                      table.resetSelected();
                    }}
                    size="extra-small"
                    iconPosition="left"
                    variant="error"
                    type="primary"
                  />
                </CustomPillButton>
              ))
            ) : (
              <></>
            )}
          </Table.BulkActions>
          <Table.Pagination
            count={employeesData?.metadata.totalCount || 0}
            onPaginationChange={({ pageSize, pageNumber }) =>
              setPagination({ ...pagination, pageSize, pageNumber })
            }
            showItemRange
            pagination={pagination}
            pageSizeOptions={pageSizeOptions}
          />
        </Table.Content>
      </Table.Root>
      <SetRoleModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        selectedEmployee={selectedEmployee}
        setRefresh={setRefresh}
      />
    </TableContainer>
  );
};
