import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { FormikProps, useFormik } from "formik";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Employee, Role, RoleModule } from "src/types";
import * as yup from "yup";
import { useRolesQuery } from "../PagePermissions/queries";
import { useGetModules } from "../PageRoleCreate/hooks/useGetModules";

export interface FormValues {
  name: string;
  description: string;
  permissions: string[];
}

export type EditRoleContextType = {
  step: number;
  goForward: () => void;
  goBack: () => void;
  setStep: (step: number) => void;
  formik: FormikProps<FormValues>;
  modules?: RoleModule[] | [];
  members: Employee[];
  setMembers: Dispatch<SetStateAction<Employee[]>>;
  roleId: string;
  setRoleId: (id: string) => void;
  equalRole?: Role;
  setEqualRole: (role: Role | undefined) => void;
  equalRoleBypass: boolean;
  setEqualRoleBypass: (value: boolean) => void;
};

export enum EDIT_ROLE_STEPS {
  "BASIC_INFO" = 0,
  "PERMISSIONS" = 1,
  "MEMBERS" = 2,
  "REVIEW" = 3,
  "FEEDBACK" = 4,
}

export const EditRoleContext = createContext<EditRoleContextType>({
  step: 0,
  goForward: () => undefined,
  goBack: () => undefined,
  setStep: () => undefined,
  formik: {} as FormikProps<FormValues>,
  modules: [],
  members: [],
  setMembers: (prevState) => [] as Employee[],
  roleId: "",
  setRoleId: (id: string) => undefined,
  equalRole: undefined,
  setEqualRole: (role: Role | undefined) => undefined,
  equalRoleBypass: false,
  setEqualRoleBypass: (value: boolean) => undefined,
});

const validationSchema = yup.object({
  name: yup.string().required("O nome do perfil é obrigatório"),
  description: yup.string().optional(),
  permissions: yup.array().optional(),
});

export const EditRoleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      description: "",
      permissions: [],
    },
    onSubmit: () => {
      if (
        permissions
          ?.map((permission) => permission.name)
          .includes(formik.values.name) &&
        permissions.find((permission) => permission.name === formik.values.name)
          ?.id !== roleId
      ) {
        formik.setErrors({
          name: "Já existe uma permissão com esse nome. Escolha outro nome para continuar.",
        });
        return;
      }
      setStep((prev) => Math.min(prev + 1, EDIT_ROLE_STEPS.FEEDBACK));
    },
    validationSchema: validationSchema,
  });
  const [step, setStep] = useState(0);
  const { selectedCompany } = useSelectedCompany();
  const { data: permissions, isLoading: permissionsLoading } = useRolesQuery({
    companyId: selectedCompany.id,
    enabled: true,
  });
  const { data: modules, isLoading: modulesLoading } = useGetModules();
  const [members, setMembers] = useState<Employee[]>([]);
  const [roleId, setRoleId] = useState<string>("");

  useEffect(() => {
    if (roleId && permissions) {
      const role = permissions.find((role) => role.id === roleId);
      if (
        !formik.values.name.length &&
        !formik.values.description.length &&
        !formik.values.permissions.length
      ) {
        formik.setFieldValue("name", role?.name);
        formik.setFieldValue("description", role?.description);
        formik.setFieldValue("permissions", role?.permissions);
      }
    }
  }, [roleId, permissions]);

  const goForward = () => {
    formik.handleSubmit();
  };
  const [equalRole, setEqualRole] = useState<Role | undefined>(undefined);
  const [equalRoleBypass, setEqualRoleBypass] = useState<boolean>(false);

  const goBack = () => {
    setStep((prev) => Math.max(prev - 1, EDIT_ROLE_STEPS.BASIC_INFO));
  };

  return (
    <EditRoleContext.Provider
      value={{
        step,
        setStep,
        goForward,
        goBack,
        formik,
        modules,
        members,
        setMembers,
        roleId,
        setRoleId,
        equalRole,
        setEqualRole,
        equalRoleBypass,
        setEqualRoleBypass,
      }}
    >
      {children}
    </EditRoleContext.Provider>
  );
};
