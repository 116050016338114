import { DataType } from "@components/TransferListV2/Root";
import { Table } from "@tanstack/react-table";
import { useMemo } from "react";
import { CustomCheckBox } from "../../styled";

export const TableHeaderCheckbox = <T extends DataType>({
  table,
  selected,
  addSelected,
  removeSelected,
}: {
  table: Table<T>;
  selected: T[];
  addSelected: (items: T[]) => void;
  removeSelected: (items: T[]) => void;
}) => {
  const tableData = useMemo(
    () =>
      table
        .getRowModel()
        .rows.map(({ original }) => original)
        .filter(({ metaData }) => !metaData?.isDisabled),
    [table]
  );

  const everyRowSelected =
    tableData.length > 0 &&
    tableData.every(({ id }) => selected.some((item) => item.id === id));
  const someRowSelected = tableData.some(({ id }) =>
    selected.some((item) => item.id === id)
  );

  return (
    <CustomCheckBox
      checked={everyRowSelected}
      indeterminate={someRowSelected && !everyRowSelected}
      onChange={(e) => {
        e.target.checked ? addSelected(tableData) : removeSelected(tableData);
      }}
    />
  );
};
