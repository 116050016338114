import { RedirectBox } from "@components/RedirectBox/redirect-box";
import {
  ShapeIcon,
  SurveyStore,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { setEventTracking } from "@utils/eventTracking";
import { useMemo } from "react";
import { routesV2 } from "src/routes";
import { Container, Header, NextStepsContainer, NextStepsRow } from "./styles";

type FeedbackData = {
  rating: number;
  message: string;
};

interface FeedbackStepProps {
  roleId: string;
  edit: boolean;
}

export const FeedbackStep = ({ roleId, edit }: FeedbackStepProps) => {
  const redirectToRolePage = useMemo(() => {
    return roleId
      ? routesV2.pageRolesPermissions.replace(":roleId", roleId)
      : routesV2.pageRoles;
  }, [roleId]);

  const handleFeedback = (data: FeedbackData) => {
    setEventTracking(edit ? "edit_role_survey" : "create_role_survey", {
      rating: data.rating.toString(),
      message: data.message,
    });
    return true;
  };

  const feedbackTitle = edit
    ? "Perfil de permissão editado com sucesso!"
    : "Perfil de permissão criado com sucesso!";

  return (
    <Container>
      <Header>
        <ShapeIcon
          variant="success"
          name="IconCircleCheck"
          size={48}
          stroke="success"
        />
        <Typography.Headline8 color="neutral.10">
          {feedbackTitle}
        </Typography.Headline8>
        <Typography.Body4 color="neutral.40">
          Os membros adicionados já possuem os novos privilégios ativos. Você
          pode ajustar essas configurações a qualquer momento na área de Perfis
          de permissão.
        </Typography.Body4>
      </Header>
      <NextStepsRow>
        <Typography.Headline9 color="neutral.20">
          Próximos passos
        </Typography.Headline9>
      </NextStepsRow>
      <NextStepsContainer>
        <RedirectBox
          shapeIcon={false}
          iconName="IconEye"
          title={edit ? "Ver permissão editada" : "Ver permissão criada"}
          description={
            edit
              ? "Ver os detalhes da permissão recém editada"
              : "Ver os detalhes da permissão recém criada"
          }
          buttonText={edit ? "Ver permissão editada" : "Ver permissão criada"}
          redirectTo={redirectToRolePage}
          buttonType="primary"
        />
        <RedirectBox
          shapeIcon={true}
          iconName="IconPlaylistX"
          title="Ver todos os perfis"
          description="Ver página com todos os perfis criados"
          buttonText="Ver todos os perfis"
          redirectTo={routesV2.pageRoles}
          buttonType="secondary"
        />
      </NextStepsContainer>
      <SurveyStore
        title={
          edit
            ? "Como você avalia a facilidade de uso da plataforma ao editar perfis de permissão?"
            : "Como você avalia a facilidade de uso da plataforma ao criar perfis de permissão?"
        }
        onSubmit={handleFeedback}
      />
    </Container>
  );
};
