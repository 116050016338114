import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditRoleContext } from "../edit-role-context";

export const useEditRole = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useContext(EditRoleContext);
  const { roleId } = useParams() as { roleId: string };
  const { formik, members, goForward, setRoleId } = context;
  const { selectedCompany } = useSelectedCompany();

  const { mutateAsync } = trpc.editRole.useMutation();

  useEffect(() => {
    setRoleId(roleId);
  }, [roleId]);

  const editRole = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await mutateAsync({
        roleId: roleId,
        name: formik.values.name,
        description: formik.values.description,
        permissions: formik.values.permissions,
        members: members.map((employee) => employee.id),
      });
      goForward();
    } catch (error: any) {
      dispatchToast({
        type: "error",
        content: "Erro ao criar perfil",
        description: error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    formik.values,
    members,
    selectedCompany.id,
    mutateAsync,
    setIsLoading,
    setRoleId,
    goForward,
  ]);

  return { ...context, isLoading, editRole };
};
