import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";

type useRoleEmployeesQueryInput = {
  roleId: string;
  pageSize: number;
  page: number;
  searchQuery?: string;
  companyId?: string;
  sortBy?: "name" | "status" | "documentNumber";
  order?: "asc" | "desc";
};

export const useRoleEmployeesQuery = (input: useRoleEmployeesQueryInput) => {
  const { data, isInitialLoading, refetch } =
    trpc.getRoleEmployeesPaginated.useQuery(input, {
      enabled: !!input.roleId,
      onError: () => {
        dispatchToast({
          content: "Não foi possível obter os perfis",
          type: "error",
        });
      },
    });

  return { data, isLoading: isInitialLoading, fetch: refetch };
};

export const useRolesQuery = (input: {
  companyId: string;
  enabled: boolean;
}) => {
  const { companyId, enabled } = input;
  const { data, isLoading } = trpc.listRoles.useQuery(
    { companyId },
    {
      enabled: enabled,
      onError: (error) => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar as permissões",
          description: error.message,
        });
      },
    }
  );
  const sortedRoles = data?.sort(
    (x, y) => Number(y.builtIn) - Number(x.builtIn)
  );
  return { data: sortedRoles, isLoading };
};

export const useDeleteRoleMutation = (input: { companyId: string }) => {
  const utils = trpc.useContext();
  const { mutate } = trpc.deleteRole.useMutation({
    onSuccess: async (_, { roleId }) => {
      await utils.listRoles.cancel();
      utils.listRoles.setData({ companyId: input.companyId }, (prev) => {
        if (!prev) return prev;
        return prev.filter((role) => role.id !== roleId);
      });
      dispatchToast({
        content: "Perfil excluído com sucesso!",
        type: "success",
      });
    },
    onError: (error) => {
      dispatchToast({
        content: "Erro ao excluir perfil de permissão",
        type: "error",
        description: error.message,
      });
    },
  });

  return { mutate };
};

export const useDeleteEmployeeFromRoleMutation = (input: {
  companyId: string;
}) => {
  const utils = trpc.useContext();
  const { mutate } = trpc.deleteEmployeeFromRole.useMutation({
    onSuccess: async (_, { role, employee }) => {
      await utils.getRoleEmployeesPaginated.invalidate();
      await utils.getRole.cancel();
      utils.getRole.setData({ roleId: role.id }, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          role: {
            ...prev.role,
            membersCount: prev.role.membersCount - 1,
            employees: prev.role.employees.filter((id) => id !== employee.id),
          },
        };
      });
      await utils.listRoles.cancel();
      utils.listRoles.setData({ companyId: input.companyId }, (prev) => {
        if (!prev) return prev;
        return prev.map((r) => {
          if (r.id === role.id) {
            return { ...r, membersCount: r.membersCount - 1 };
          }
          return r;
        });
      });
      dispatchToast({
        content: `Membro removido com sucesso`,
        description: role?.name
          ? `${employee.name} foi removido do perfil de permissão "${role.name}" com sucesso!`
          : `${employee.name} foi removido do perfil de permissão com sucesso!`,
        type: "success",
      });
    },
    onError: () => {
      dispatchToast({
        content: "Erro ao remover membro",
        description:
          "Tente novamente mais tarde, ou entre em contato com a nossa Central de Ajuda.",
        type: "error",
      });
    },
  });

  return { mutate };
};
