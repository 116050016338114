import {
  Button,
  Checkbox,
  Icons,
  LinkButton,
  Modal,
  PillButton,
  Table,
  tableControllers,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { normalizeText } from "@utils/normalizeText";
import { uniqBy } from "lodash-es";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useRolesQuery } from "src/pages/PagePermissions/queries";
import { usePermissionsTable } from "src/pages/PagePermissionsV2/components/Tabs/components/PermissionsTable/hooks/usePermissionsTable";
import { routesV2 } from "src/routes";
import {
  Container,
  FooterContainer,
  Header,
  StyledContentContainer,
  TextWrapper,
} from "./styles";
import { useUpdateEmployeeRoles } from "./useUpdateEmployeeRoles";

interface SetRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  selectedEmployee: {
    employeeName: string;
    employeeId: string;
    employeeRoles: { id: string; name: string }[];
  };
  isLoading?: boolean;
}

const TABLE_SIZE = 30;
export const SetRoleModal = ({
  isOpen,
  onClose,
  selectedEmployee,
  setRefresh,
}: SetRoleModalProps) => {
  const [selectedRoles, setSelectedRoles] = useState<
    { id: string; name: string }[]
  >(selectedEmployee?.employeeRoles || []);
  const { selectedCompany } = useSelectedCompany();
  const [pagination, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: TABLE_SIZE,
  });
  const { search, setSearch } = usePermissionsTable();

  const { data: permissions } = useRolesQuery({
    companyId: selectedCompany.id,
    enabled: true,
  });

  const { updateEmployeeRoles, isLoading } = useUpdateEmployeeRoles({
    selectedEmployee,
    currentEmployeeRoles: selectedRoles,
    redirectTo: routesV2.pageRoles,
  });

  const addSelected = (roleToAdd: [{ id: string; name: string }]) => {
    setSelectedRoles((prev) => {
      return uniqBy([...prev, ...roleToAdd], "id");
    });
  };

  const removeSelected = (roleToRemove: [{ id: string; name: string }]) => {
    const itemsToRemoveIds = roleToRemove.map(({ id }) => id);
    setSelectedRoles((prev) =>
      prev.filter(({ id }) => !itemsToRemoveIds.includes(id))
    );
  };

  useEffect(() => {
    if (selectedEmployee?.employeeRoles) {
      setSelectedRoles(selectedEmployee.employeeRoles);
    }
  }, [selectedEmployee]);

  const { filteredData, totalFiltered } = useMemo(() => {
    let dataView = permissions;
    if (search && dataView) {
      dataView = dataView.filter((permission) =>
        [normalizeText(permission.name), normalizeText(permission.description)]
          .join(" ")
          .includes(search)
      );
    }
    const totalFiltered = dataView?.length;

    return { filteredData: dataView, totalFiltered };
  }, [permissions, search]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "select",
        maxSize: 10,
        cell: ({ row }: any) => (
          <Checkbox
            checked={selectedRoles?.some(
              (role) => role.id === row?.original?.id
            )}
            onChange={(e) => {
              if (e.target.checked) {
                addSelected([
                  { id: row.original?.id, name: row.original?.name || "" },
                ]);
              } else {
                removeSelected([
                  { id: row.original?.id, name: row.original?.name || "" },
                ]);
              }
            }}
            aria-label="Select row"
          />
        ),
      },
      {
        header: "Nome do perfil de permissão",
        accessorKey: "name",
        size: 100,
        cell: ({ row }: any) => (
          <Typography.Body4 weight={600} color="neutral.20">
            {row?.original?.name}
          </Typography.Body4>
        ),
      },
    ];
  }, [addSelected, removeSelected, selectedRoles]);
  const table = tableControllers.useTableColumns<any>({
    defaultColumn: {
      minSize: 10,
    },
    total: totalFiltered,
    columns: tableColumns,
    data: filteredData || [],
    pagination: pagination,
    onPaginationChange: setPaginationState,
  });

  return (
    <Modal.Root
      header={false}
      footer={false}
      size="xs"
      open={isOpen}
      onClose={() => onClose()}
      showClose={false}
    >
      <Container>
        <Header>
          <PillButton
            onClick={onClose}
            icon="IconX"
            variant="default"
            type="secondary"
            size="extra-small"
          />
        </Header>
        <TextWrapper>
          <Typography.Headline8 color="neutral.10">
            Editar perfis de permissão
          </Typography.Headline8>
          <Typography.Body4 color="neutral.40">
            Selecione um ou mais perfis de permissão para aplicar a esta pessoa.
          </Typography.Body4>
        </TextWrapper>
        <StyledContentContainer>
          <Table.Root>
            <Table.Search
              label="Buscar por perfis de permissões da empresa"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Table.Grid.Root>
              <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              {table.rows.map((row) => (
                <Table.Grid.Row key={row.id} row={row} />
              ))}
            </Table.Grid.Root>
          </Table.Root>
        </StyledContentContainer>
        <FooterContainer>
          <LinkButton onClick={() => onClose()} variant="neutral">
            Cancelar
          </LinkButton>
          <Button
            loading={isLoading}
            disabled={false}
            onClick={() => {
              updateEmployeeRoles();
              onClose();
              setRefresh((prev) => !prev);
            }}
            variant="primary"
            size="medium"
          >
            Confirmar
            <Icons name="IconArrowRight" />
          </Button>
        </FooterContainer>
      </Container>
    </Modal.Root>
  );
};
