import {
  Icons,
  LinkButton,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Container, StyledBreadcrumbs } from "./styled";

interface RoutesProps {
  route?: string;
  label: string;
}

interface HeaderProps {
  routes: RoutesProps[];
}

export const Header = ({ routes }: HeaderProps) => {
  const navigate = useNavigate();

  const breadCrumbs = useMemo(
    () =>
      routes.map(({ label, route }) => {
        return (
          <LinkButton
            variant="neutral"
            onClick={() => route && navigate(route)}
          >
            {label}
          </LinkButton>
        );
      }),
    [routes, navigate]
  );

  return (
    <Container>
      <PillButton
        variant={"default"}
        size={"small"}
        icon="IconArrowLeft"
        onClick={() => navigate(-1)}
      />
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
      />
    </Container>
  );
};
