import {
  Divider,
  Icons,
  ShapeIcon,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { featureToIcon } from "src/pages/PageRole/components/Tabs/components/RolePermissions/featureToIcon";
import { Feature } from "src/types";
import { useTheme } from "styled-components";
import {
  FeatureCard,
  FeatureHeader,
  FeatureInfo,
  FinalDivider,
  ListContainer,
  PermissionInfo,
  PermissionList,
} from "./styles";
interface RolePermissionsSummary {
  features?: Feature[];
}

export const RolePermissionsSummary = ({
  features,
}: RolePermissionsSummary) => {
  const theme = useTheme();

  if (features?.length === 0) {
    return <>-</>;
  }

  return (
    <ListContainer>
      {features?.map((feature) => (
        <FeatureCard>
          <FeatureHeader>
            <ShapeIcon
              variant="neutral"
              name={featureToIcon?.[feature.id] || "IconSettings"}
              size={32}
            />
            <FeatureInfo>
              <Typography.Headline9 color="neutral.20">
                {feature.name}
              </Typography.Headline9>
              <Typography.Body4 color="neutral.40">
                {feature.permissions.length > 1
                  ? `${feature.permissions.length} permissões selecionadas`
                  : `${feature.permissions.length} permissão selecionada`}
              </Typography.Body4>
            </FeatureInfo>
          </FeatureHeader>
          <PermissionList>
            {feature.permissions.map((permission, index) => (
              <>
                <PermissionInfo>
                  <Typography.Body4 weight={400} color="neutral.40">
                    {permission.name}
                  </Typography.Body4>
                  {permission.description && (
                    <Tooltip
                      title={permission.description}
                      placement="right-start"
                    >
                      <Icons
                        name="IconInfoCircle"
                        size={12}
                        color={theme.colors.neutral[30]}
                      />
                    </Tooltip>
                  )}
                </PermissionInfo>
                {index < feature.permissions.length - 1 ? (
                  <Divider orientation="horizontal" />
                ) : (
                  <FinalDivider orientation="horizontal" />
                )}
              </>
            ))}
          </PermissionList>
        </FeatureCard>
      ))}
    </ListContainer>
  );
};
