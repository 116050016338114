import { EmptyStateType } from "@components/EmployeesTable/components/EmptyState/employees-table-empty-state";
import { BulkAction, EmployeesTable } from "@components/index";
import { useDeleteActions } from "@components/TransferListV2/selectedList/list/useDeleteActions";
import { WarningModal } from "@components/WarningModal/warning-modal";
import {
  Breadcrumbs,
  Button,
  Icons,
  LinkButton,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesV2 } from "src/routes";
import { useGetAdminsIds } from "../PageSetAdmins/queries";
import { SuperAdminActions } from "./components/SuperAdminsActions/super-admins-actions";
import { HeaderContainer, HeaderWrapper } from "./styles";
export const PageSuperAdmins = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const { data: adminsIds } = useGetAdminsIds();
  const { deleteSuperAdmin } = useDeleteActions();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ id: string; name: string }[]>([]);

  const bulkDeleteAction: BulkAction = useMemo(() => {
    return {
      key: "deleteSuperAdmin",
      label: "Remover membros deste perfil de permissão",
      onClick: (selected: { id: string; name: string }[]) => {
        setSelected(selected);
        setIsModalOpen(true);
      },
      icon: "IconUserMinus",
    };
  }, [setSelected, setIsModalOpen]);

  const handleBulkDelete = async (selected: { id: string; name: string }[]) => {
    await Promise.all(selected.map(({ id }) => deleteSuperAdmin(id)));
  };

  return (
    <PageContainer>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton variant="neutral" onClick={() => navigate("/settings")}>
            Gerenciar empresa
          </LinkButton>,
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routesV2.pageRoles)}
          >
            Permissões
          </LinkButton>,
          <LinkButton variant="neutral" onClick={() => null}>
            Super Administradores
          </LinkButton>,
        ]}
      />
      <HeaderContainer>
        <HeaderWrapper>
          <Typography.Headline7 color="neutral.20">
            Super administradores
          </Typography.Headline7>
          <Typography.Body4 color="neutral.40">
            Os super administradores possuem nível máximo de permissão na
            plataforma, tendo acesso a informações e configurações de todas as
            empresas do grupo.
          </Typography.Body4>
        </HeaderWrapper>
        <Button
          size="medium"
          variant="primary"
          onClick={() => navigate(routesV2.pageSetGlobalAdmins)}
        >
          Atribuir novos super administradores{" "}
          <Icons name="IconPlus" size={16} />
        </Button>
      </HeaderContainer>
      <EmployeesTable
        companyId={selectedCompany.id}
        employeeIds={adminsIds}
        emptyStateType={EmptyStateType.MEMBERS}
        CustomActions={SuperAdminActions}
        superAdmins={true}
        bulkActions={[bulkDeleteAction]}
      />
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        modalSize="xs"
        isOpen={isModalOpen}
        title="Você tem certeza que deseja remover estas pessoas do perfil de super administradores?"
        description="Ao desvincular estas pessoas do perfil de super administradores, elas não terão mais permissão para gerenciar informações e configurações das empresas do grupo econômico. Certifique-se de que essa alteração é essencial."
        footerConfig={{
          text: "Sim, remover",
          icon: "IconTrash",
          buttonSize: "small",
          variant: "primary",
          iconColor: "negative",
        }}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          handleBulkDelete(selected);
          setIsModalOpen(false);
        }}
      />
    </PageContainer>
  );
};
