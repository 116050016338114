import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { FormikProps, useFormik } from "formik";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Employee, Role, RoleModule } from "src/types";
import * as yup from "yup";
import { useRolesQuery } from "../PagePermissions/queries";
import { useGetModules } from "./hooks/useGetModules";

export interface FormValues {
  name: string;
  description: string;
  permissions: string[];
}

export type CreateRoleContextType = {
  step: number;
  goForward: () => void;
  goBack: () => void;
  setStep: (step: number) => void;
  formik: FormikProps<FormValues>;
  modules?: RoleModule[] | [];
  members: Employee[];
  setMembers: Dispatch<SetStateAction<Employee[]>>;
  roleId: string;
  setRoleId: (id: string) => void;
  equalRole?: Role;
  setEqualRole: (role: Role | undefined) => void;
  equalRoleBypass: boolean;
  setEqualRoleBypass: (value: boolean) => void;
};

export enum CREATE_ROLE_STEPS {
  "BASIC_INFO" = 0,
  "PERMISSIONS" = 1,
  "MEMBERS" = 2,
  "REVIEW" = 3,
  "FEEDBACK" = 4,
}

export const CreateRoleContext = createContext<CreateRoleContextType>({
  step: 0,
  goForward: () => undefined,
  goBack: () => undefined,
  setStep: () => undefined,
  formik: {} as FormikProps<FormValues>,
  modules: [],
  members: [],
  setMembers: (prevState) => [] as Employee[],
  roleId: "",
  setRoleId: (id: string) => undefined,
  equalRole: undefined,
  setEqualRole: (role: Role | undefined) => undefined,
  equalRoleBypass: false,
  setEqualRoleBypass: (value: boolean) => undefined,
});

const validationSchema = yup.object({
  name: yup.string().required("O nome do perfil é obrigatório"),
  description: yup.string().optional(),
  permissions: yup.array().optional(),
});

export const CreateRoleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      description: "",
      permissions: [],
    },
    onSubmit: () => {
      if (
        permissions
          ?.map((permission) => permission.name)
          .includes(formik.values.name)
      ) {
        formik.setErrors({
          name: "Já existe uma permissão com esse nome. Escolha outro nome para continuar.",
        });
        return;
      }

      const equalRole = permissions?.find((permission) => {
        const permissionValues = permission.permissions ?? [];
        const formikPermissions = formik.values.permissions ?? [];
        return comparePermissions(permissionValues, formikPermissions);
      });
      if (
        equalRole &&
        !equalRoleBypass &&
        step === CREATE_ROLE_STEPS.PERMISSIONS
      ) {
        setEqualRole(equalRole);
        return;
      }

      setStep((prev) => Math.min(prev + 1, CREATE_ROLE_STEPS.FEEDBACK));
    },
    validationSchema: validationSchema,
  });
  const [step, setStep] = useState(0);
  const { selectedCompany } = useSelectedCompany();
  const { data: permissions, isLoading: permissionsLoading } = useRolesQuery({
    companyId: selectedCompany.id,
    enabled: true,
  });
  const { data: modules, isLoading: modulesLoading } = useGetModules();
  const [members, setMembers] = useState<Employee[]>([]);
  const [roleId, setRoleId] = useState<string>("");

  const goForward = () => {
    formik.handleSubmit();
  };
  const [equalRole, setEqualRole] = useState<Role | undefined>(undefined);
  const [equalRoleBypass, setEqualRoleBypass] = useState<boolean>(false);

  const goBack = () => {
    setStep((prev) => Math.max(prev - 1, CREATE_ROLE_STEPS.BASIC_INFO));
  };

  const comparePermissions = (firstArray: string[], secondArray: string[]) => {
    if (firstArray.length === 0 && secondArray.length === 0) return false;
    if (firstArray.length !== secondArray.length) return false;
    const secondSet = new Set(secondArray);
    return firstArray.every((item) => secondSet.has(item));
  };

  return (
    <CreateRoleContext.Provider
      value={{
        step,
        setStep,
        goForward,
        goBack,
        formik,
        modules,
        members,
        setMembers,
        roleId,
        setRoleId,
        equalRole,
        setEqualRole,
        equalRoleBypass,
        setEqualRoleBypass,
      }}
    >
      {children}
    </CreateRoleContext.Provider>
  );
};
