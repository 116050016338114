import { Icons, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { FormikProps } from "formik";
import { useCallback, useMemo } from "react";
import { featureToIcon } from "src/pages/PageRole/components/Tabs/components/RolePermissions/featureToIcon";
import { FormValues } from "src/pages/PageRoleCreate/create-role-context";
import { StyledCheckBox } from "src/pages/PageRoleCreate/styles";
import { Feature } from "src/types";
import { useTheme } from "styled-components";
import {
  Container,
  FeatureDescription,
  LeftContainer,
  RightContainer,
} from "./styles";

interface FeatureAccordionHeaderProps {
  feature: Feature;
  expanded: boolean;
  formik: FormikProps<FormValues>;
}

export const FeatureAccordionHeader = ({
  feature,
  expanded,
  formik,
}: FeatureAccordionHeaderProps) => {
  const theme = useTheme();

  const allPermissionsSelected = useMemo(() => {
    return feature?.permissions.every((permission) =>
      formik.values?.permissions.includes(permission.id)
    );
  }, [formik.values]);

  const selectedPermissionsCount = useMemo(() => {
    return feature.permissions.filter((permission) =>
      formik.values?.permissions.includes(permission.id)
    ).length;
  }, [formik.values?.permissions, feature?.permissions]);

  const handleSelectAll = useCallback(
    (shouldSelect: boolean) => {
      const featurePermissionIds = feature?.permissions.map((p) => p.id);

      const newPermissions = shouldSelect
        ? [...new Set([...formik.values?.permissions, ...featurePermissionIds])]
        : formik.values?.permissions.filter(
            (id) => !featurePermissionIds.includes(id)
          );

      formik.setFieldValue("permissions", newPermissions);
    },
    [formik, feature.permissions]
  );

  return (
    <Container>
      <LeftContainer>
        <Icons
          size={16}
          name={expanded ? "IconChevronUp" : "IconChevronDown"}
          color={theme.colors.neutral[40]}
        />
        <ShapeIcon
          variant="neutral"
          name={featureToIcon?.[feature.id] || "IconSettings"}
          color={theme.colors.neutral[40]}
          size={32}
        />
        <FeatureDescription>
          <Typography.Headline9>{feature.name}</Typography.Headline9>
          <Typography.Body4 color="neutral.40">{`${selectedPermissionsCount} de ${feature?.permissions.length} permissões selecionadas`}</Typography.Body4>
        </FeatureDescription>
      </LeftContainer>
      <RightContainer>
        <StyledCheckBox
          checked={allPermissionsSelected}
          onChange={() => handleSelectAll(!allPermissionsSelected)}
        />
        <Typography.Body4 color="neutral.30">Selecionar todas</Typography.Body4>
      </RightContainer>
    </Container>
  );
};
