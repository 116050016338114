import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

interface HighLightSearchMatch {
  text: string;
  searchQuery?: string;
}

export const HighLightSearchMatch = ({
  text,
  searchQuery = "",
}: HighLightSearchMatch) => {
  if (!searchQuery) return text;

  const normalize = (str: string) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "");

  const cleanText = normalize(text);
  const cleanQuery = normalize(searchQuery);
  const matchIndex = cleanText.indexOf(cleanQuery);

  if (matchIndex === -1) return text;

  let cleanPos = 0;
  const positionMap: number[] = [];

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (normalize(char).length > 0) {
      positionMap[cleanPos] = i;
      cleanPos++;
    }
  }

  const start = positionMap[matchIndex];
  const end = positionMap[matchIndex + cleanQuery.length - 1] + 1;

  return (
    <>
      {text.substring(0, start)}
      <Typography.Body4 weight={800} as="span">
        {text.substring(start, end)}
      </Typography.Body4>
      {text.substring(end)}
    </>
  );
};
