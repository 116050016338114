import {
  Checkbox,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.span`
  width: calc(100% + 2 * ${({ theme }) => theme.spacings.s});
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
  margin: ${({ theme }) => theme.spacings.s} 0px;
  margin-left: -${({ theme }) => theme.spacings.s};
`;

const OptionsContainer = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 17px;
`;

const IconText = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  & {
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.neutral[30]};
    text-align: left;
    user-select: none;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 9px;
`;

const StyledCheckbox = styled(Checkbox)`
  transform: scale(0.8);
  & {
    padding-left: 0px;
  }
`;

export {
  Divider,
  FlexColumn,
  FlexRow,
  IconText,
  OptionsContainer,
  StyledCheckbox,
  StyledTooltip,
  StyledTypography,
  TitleWrapper,
};
