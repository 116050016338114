import styled from "styled-components";

export const Container = styled.div<{ $hovered: boolean }>`
  ${({ theme, $hovered }) =>
    $hovered
      ? `{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 40px;
  border: 1px solid ${theme.colors.neutral[90]};
}`
      : `{}`}
`;

export const MenuOption = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 6px;
`;
