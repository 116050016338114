import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;
  padding: 12px 40px;
`;

export const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: var(--color-neutral-50);

  margin: 16px 0;
`;

type ButtonColor = "negative" | "pink";

interface FooterSubmitButtonProps {
  $enabled: boolean;
  $color: ButtonColor;
}

export const FooterSubmitButton = styled.div<FooterSubmitButtonProps>`
  ${({ $enabled, $color, theme }) => {
    if ($enabled) {
      const textColor =
        $color === "pink"
          ? theme.colors.neutral[100]
          : theme.colors.status.negative[40];

      const backgroundColor =
        $color === "pink"
          ? theme.colors.secondary[50]
          : theme.colors.status.negative[90];

      return `
        .MuiButtonBase-root {
          color: ${textColor} !important;
          background-color: ${backgroundColor} !important;
        }
        .MuiButtonBase-root > svg {
          color: ${textColor} !important;
        }
      `;
    }

    return `
      .MuiButtonBase-root {
        color: ${theme.colors.status.inactive[50]} !important;
        background-color: ${theme.colors.status.inactive[90]} !important;
      }
      .MuiButtonBase-root > svg {
        color: ${theme.colors.status.inactive[40]} !important;
      }
    `;
  }}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  max-width: 400px;
  text-align: left;
`;

export const Warning = styled(Typography)`
  && {
    font-weight: 700;
    color: var(--color-feedback-error-pure);
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: var(--color-neutral-dark1);

  margin-bottom: 16px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: var(--color-neutral-50);
`;

export const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const ConfirmWordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AuditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 400px;
`;

export const ConfirmDescription = styled(Typography)`
  color: var(--color-neutral-30) !important;
  font-weight: 700 !important;
  margin: 44px 0 16px !important;
`;

export const ConfirmInput = styled(TextField)`
  width: 100%;
`;

export const OptionCard = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs5};
  padding: ${({ theme }) => theme.spacings.xs2};
  border-radius: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid
    ${({ theme, $selected }) =>
      $selected
        ? `${theme.colors.secondary[50]};`
        : `${theme.colors.neutral[90]}`};
  background-color: ${({ theme, $selected }) =>
    $selected
      ? `${theme.colors.secondary[99]};`
      : `${theme.colors.neutral[100]}`};
  max-width: 60vh;
  justify-content: space-between;
  align-items: center;
`;

export const OptionCardTextWrapper = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
`;
