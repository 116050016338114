import { IconsProps } from "@flash-tecnologia/hros-web-ui-v2";

export type Action = {
  label: string;
  key: string;
  icon: IconsProps["name"];
  onClick: () => void;
};

export type PermissionRole = {
  id: string;
  name: string;
};

export type Department = {
  id: string;
  name: string;
};

export type Employee = {
  id: string;
  name: string;
  documentNumber: string;
  status: string;
  role?: string;
  departments?: Department[];
  permissionRoles?: PermissionRole[];
};

export type PaginationMetadata = {
  totalCount: number;
  totalPages: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number | null;
  prevPage: number | null;
};

export type SelectedCompany = {
  id: string;
  name: string;
  registrationNumber: string;
};

export enum TableViews {
  ALL = "ALL",
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}
