import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { routesV2 } from "src/routes";
interface EmployeesTableEmptyStateProps {
  searchQuery: string;
  emptyStateType: EmptyStateType;
  roleId: string;
}

export enum EmptyStateType {
  MEMBERS = 0,
  EMPLOYEES = 1,
}

export const EmployeesTableEmptyState = ({
  searchQuery,
  emptyStateType,
  roleId,
}: EmployeesTableEmptyStateProps) => {
  const navigate = useNavigate();

  if (emptyStateType === EmptyStateType.MEMBERS) {
    return (
      <EmptyState
        buttonText={"Adicionar integrantes"}
        description={
          searchQuery.length > 0
            ? "Não encontramos nenhum colaborador para esta busca."
            : "Você ainda não adicionou ninguém a esse perfil de permissão."
        }
        buttonProps={{
          size: "medium",
          onClick: () =>
            navigate(
              routesV2.pageRolesAddMembers.replace(":roleId", roleId || "")
            ),
        }}
      />
    );
  }

  return (
    <EmptyState
      description={
        searchQuery.length > 0
          ? "Não encontramos nenhum colaborador para esta busca."
          : "Você ainda não adicionou ninguém a esse perfil de permissão."
      }
      withoutButton={true}
    />
  );
};
