import {
  Icons,
  IconsProps,
  ShapeIcon,
  ShapeIconProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { Box, StyledPillButton, Title } from "./styles";

interface RedirectBoxProps {
  shapeIcon?: boolean;
  iconName: IconsProps["name"] | ShapeIconProps["name"];
  title: string;
  description: string;
  buttonText: string;
  buttonType: "primary" | "secondary";
  redirectTo: string;
}

export const RedirectBox = ({
  shapeIcon,
  iconName,
  title,
  description,
  buttonText,
  buttonType,
  redirectTo,
}: RedirectBoxProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box>
      <Title>
        {shapeIcon ? (
          <ShapeIcon
            name={iconName}
            size={24}
            variant="default"
            color={theme.colors.secondary[50]}
          />
        ) : (
          <Icons name={iconName} size={24} color={theme.colors.secondary[50]} />
        )}
        <Typography.Body3 color="neutral.30">{title}</Typography.Body3>
      </Title>
      <Typography.Body4 color="neutral.40">{description}</Typography.Body4>
      <StyledPillButton
        variant="pink"
        size="small"
        type={buttonType}
        label={buttonText}
        iconPosition={"right"}
        icon={"IconArrowNarrowRight"}
        onClick={() => navigate(redirectTo)}
      />
    </Box>
  );
};
