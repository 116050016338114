import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { routesV2 } from "src/routes/index";
import { Container, Wrapper } from "./styles";
export const Header = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <Typography.Headline7 color="neutral.20">
          Perfis de permissão
        </Typography.Headline7>
        <Typography.Body4 color="neutral.40">
          Defina diferentes níveis de permissão para as pessoas da empresa.
        </Typography.Body4>
      </Wrapper>
      <Button
        size="medium"
        variant="primary"
        onClick={() => navigate(routesV2.pageRolesCreate)}
      >
        Criar novo perfil de permissão <Icons name="IconShieldLock" size={16} />
      </Button>
    </Container>
  );
};
