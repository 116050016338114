import { useEmployeesTable } from "@components/EmployeesTable/hooks/useEmployeesTable";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect } from "react";
export const useGetSuperAdmins = (employeeIds?: string[]) => {
  const { selectedCompany } = useSelectedCompany();
  const { isAdmin } = usePermissions();
  const { data, fetchEmployees, isLoading } = useEmployeesTable();

  useEffect(() => {
    if (employeeIds?.length) {
      fetchEmployees({
        companyId: selectedCompany.id!,
        ...(isAdmin && { restrictToCompany: false }),
        employeeIds: employeeIds,
        page: 1,
        pageSize: employeeIds.length,
      });
    }
  }, [employeeIds]);

  return { superAdmins: data?.employees, isLoading };
};
