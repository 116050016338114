import {
  EmptyState,
  Icons,
  Table as TableDS,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination/index";
import { ColumnDef } from "@tanstack/react-table";
import { setEventTracking } from "@utils/eventTracking";
import { useCachedRole } from "@utils/useCachedRole";
import { Dispatch, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataType, useTransferList } from "../Root";
import { TableHeaderCheckbox } from "./components/TableHeaderCheckBox/table-header-checkbox";
import { TableRowCheckbox } from "./components/TableRowCheckBox/table-row-checkbox";
import { Container } from "./styled";

interface ModalConfig {
  title: string;
  description: string;
  onSubmit: () => void;
}

export type tableProps<T> = {
  loading: boolean;
  tableTitle: string;
  tableTagLabel: string;
  data: T[];
  pagination: PaginationState;
  onSearchChange: (value: string) => void;
  onPaginationChange: Dispatch<PaginationState>;
  columns: ColumnDef<T>[];
  dataSize: number;
};

export function Table<T extends DataType>({
  loading,
  tableTitle,
  tableTagLabel,
  data,
  columns,
  pagination,
  onSearchChange,
  onPaginationChange,
  dataSize,
}: tableProps<T>) {
  const { roleId } = useParams() as { roleId: string };
  const location = useLocation();
  const isAdminPath = location.pathname.split("/").includes("administrators");
  const { selected, addSelected, removeSelected } = useTransferList();
  const cachedRole = useCachedRole(roleId);
  const navigate = useNavigate();

  const handleEmptyStateClick = () => {
    setEventTracking("create_employee_invite_empty_state_clicked");
    navigate("/employees");
  };

  const tableColumns = useMemo(() => {
    const selectColumn: ColumnDef<T> = {
      id: "select",
      accessorKey: "id",
      header: ({ table }) => (
        <TableHeaderCheckbox
          table={table}
          selected={selected}
          addSelected={addSelected}
          removeSelected={removeSelected}
        />
      ),
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <TableRowCheckbox
            rowData={rowData}
            isAdminPath={isAdminPath}
            selected={selected}
            role={{ id: roleId, name: cachedRole?.name || "" }}
            addSelected={addSelected}
            removeSelected={removeSelected}
          />
        );
      },
    };

    return [selectColumn, ...columns];
  }, [addSelected, columns, isAdminPath, removeSelected, roleId, selected]);

  const table = tableControllers.useTableColumns<any>({
    total: dataSize,
    defaultColumn: {
      maxSize: 150,
    },
    columns: tableColumns as any,
    data: data || [],
    pagination: pagination,
    onPaginationChange: onPaginationChange,
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [search, debounceSearch] = tableControllers.useSearch(() => {}, "", {
    wait: 200,
  }) as [string, (_: string) => void];

  useEffect(() => {
    onPaginationChange({
      pageSize: pagination.pageSize,
      pageNumber: 1,
    });
    onSearchChange(search);
  }, [search, onSearchChange, onPaginationChange, pagination.pageSize]);

  const emptyStateConditions = !loading && !data.length;

  return (
    <Container>
      <TableDS.Root variant="soft">
        <TableDS.Header
          title={tableTitle}
          tagLabel={tableTagLabel}
          tag={"gray"}
          loading={loading}
        />
        <TableDS.Content>
          <TableDS.Search
            label="Pesquisar..."
            onChange={() => {
              //do nothing
            }}
            onInputChange={(_, e) => {
              debounceSearch(e!);
            }}
          />
          {emptyStateConditions ? (
            <EmptyState
              title={"Nenhuma pessoa encontrada"}
              description={
                "Revise a busca ou cadastre essa nova pessa para adicioná-la à permissão."
              }
              buttonProps={{
                size: "small",
                onClick: () => handleEmptyStateClick(),
              }}
              buttonText={
                <>
                  <span>Cadastrar novo membro</span>
                  <Icons name="IconUserPlus" />
                </>
              }
            />
          ) : (
            <TableDS.Grid.Root loading={loading}>
              <TableDS.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              {table.rows.map((row, index) => (
                <TableDS.Grid.Row key={index + row.id} row={row} />
              ))}
            </TableDS.Grid.Root>
          )}

          <TableDS.Pagination
            count={dataSize}
            onPaginationChange={({ pageSize, pageNumber }) =>
              onPaginationChange({ ...pagination, pageSize, pageNumber })
            }
            pagination={pagination}
          />
        </TableDS.Content>
      </TableDS.Root>
    </Container>
  );
}
