import styled from "styled-components";

export const Container = styled.div<{ $hovered: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 32px;
  width: ${({ $hovered }) => ($hovered ? "320px" : "80px")};
  gap: ${({ $hovered }) => ($hovered ? "4px" : "2px")};
  position: ${({ $hovered }) => ($hovered ? "absolute" : "relative")};
  right: ${({ $hovered }) => ($hovered ? "50%" : "auto")};
  top: ${({ $hovered }) => ($hovered ? "0" : "auto")};
  z-index: ${({ $hovered }) => ($hovered ? "10" : "auto")};
  border: ${({ $hovered, theme }) =>
    $hovered ? "auto" : `1px solid ${theme.colors.neutral[90]}}`};
  border-radius: ${({ $hovered }) => ($hovered ? "0px" : "80px")};
  background: ${({ $hovered, theme }) =>
    $hovered ? "transparent" : `${theme.colors.neutral[100]}`};
`;

export const QuickAction = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  box-shadow: 0px 4px 24px 0px #0000000f;
  border-radius: 40px;
  max-witdh: 200px;
  height: 32px;
  padding: 8px;
  gap: 4px;
  background: ${({ theme }) => theme.colors.neutral[100]};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 32px;
`;
