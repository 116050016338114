import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { FormikProps } from "formik";
import { useCallback } from "react";
import { StyledCheckBox } from "src/pages/PageRoleCreate/styles";
import { FormValues } from "src/pages/PageRoleEdit/edit-role-context";
import { RoleModule } from "src/types";
import { FeatureAccordion } from "../FeatureAccordion/feature-accordion";
import { CheckContainer, ModuleContainer, ModuleTitle } from "./styles";

interface ModuleWrapperProps {
  module: RoleModule;
  searchQuery?: string;
  formik: FormikProps<FormValues>;
}

export const ModuleWrapper = ({
  module,
  searchQuery,
  formik,
}: ModuleWrapperProps) => {
  const allPermissionsSelected = module.features.every((feature) =>
    feature?.permissions.every((permission) =>
      formik.values?.permissions.includes(permission.id)
    )
  );

  const handleSelectAll = useCallback(
    (shouldSelect: boolean) => {
      const modulePermissionIds = module.features.flatMap((feature) =>
        feature.permissions.map((p) => p.id)
      );
      const newPermissions = shouldSelect
        ? [...new Set([...formik.values.permissions, ...modulePermissionIds])]
        : formik.values.permissions.filter(
            (id) => !modulePermissionIds.includes(id)
          );

      formik.setFieldValue("permissions", newPermissions);
    },
    [formik, module.features]
  );

  return (
    <ModuleContainer>
      <ModuleTitle>
        <Typography.Headline9 color="neutral.20" key={module.name}>
          {module.name}
        </Typography.Headline9>
        <CheckContainer>
          <StyledCheckBox
            checked={allPermissionsSelected}
            onChange={() => handleSelectAll(!allPermissionsSelected)}
          />
          <Typography.Body4 color="neutral.30">
            Selecionar todas
          </Typography.Body4>
        </CheckContainer>
      </ModuleTitle>
      {module.features
        .filter((feature) => feature.permissions.length)
        .map((feature, index) => {
          return (
            <FeatureAccordion
              key={`${feature.id}_${index}`}
              feature={feature}
              searchQuery={searchQuery}
              formik={formik}
            />
          );
        })}
    </ModuleContainer>
  );
};
