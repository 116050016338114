import styled from "styled-components";

export const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 12px;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
