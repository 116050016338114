import { dayjs, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";

interface AuditColumnProps {
  date?: string;
  agent?: string;
}

export const AuditColumn = ({ date, agent }: AuditColumnProps) => {
  return (
    <Container>
      <Typography.Body4 color="neutral.20">{agent}</Typography.Body4>
      {date && dayjs(date).isValid() ? (
        <Typography.Body4 color="neutral.40">
          {" "}
          Em {dayjs(date).format("DD/MM/YYYY")}
        </Typography.Body4>
      ) : (
        <Typography.Body4 color="neutral.40">-</Typography.Body4>
      )}
    </Container>
  );
};
