import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  jusfity-content: center;
  align-items: center;
  width: 25%;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const CustomTableSpacing = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  && table > thead > tr > th:first-child {
    width: 1%;
  }
`;

export const CustomTableTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings.xs3};
`;
