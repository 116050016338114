import { IconsProps } from "@flash-tecnologia/hros-web-ui-v2";

export const featureToIcon: Record<string, IconsProps["name"]> = {
  employee_management: "IconUsers",
  settings: "IconSettings",
  finance_bills: "IconReceipt2",
  card_management: "IconCreditCard",
  operations_card_management: "IconCreditCard",
  contract_management: "IconFileText",
  document_management: "IconFolder",
  payroll_loan: "IconBusinessplan",
  benefit_orders: "IconColorSwatch",
  deposits: "IconColorSwatch",
  reports: "IconPresentation",
  finance: "IconCoin",
  benefits: "IconColorSwatch",
  simple_assignment: "IconColorSwatch",
  engagement: "IconSpeakerphone",
  insights: "IconChartPie",
  performance: "IconRocket",
  orgchart: "IconHierarchy",
  lms: "IconLayoutKanban",
  flow_management: "IconFolder",
  time_and_attendence: "IconClock",
  recruitment: "IconLayoutKanban",
  travel: "IconPlaneTilt",
  exp_advances: "IconCalculator",
  exp_configuration: "IconAdjustments",
  exp_finance: "IconCalculator",
  exp_approvals: "IconThumbUp",
  exp_analytics: "IconDashboard",
  expenses: "IconReportSearch",
};
