import { PageContainer, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { RoleBasicInfo } from "@components/RoleBasicInfo/role-basic-info";
import { RolePermissionsSummary } from "@components/RolePermissionsSummary/role-permissions-summary";
import { Feature, Role } from "src/types";
import { BasicInfoContainer, FeaturesContainer, Title } from "./styles";

export const RolePermissions = ({
  role,
  features,
}: {
  role?: Role;
  features?: Feature[];
}) => {
  return (
    <PageContainer>
      <BasicInfoContainer>
        <Title>
          <Typography.Headline8 weight={700} color="neutral.20">
            Informações básicas
          </Typography.Headline8>
        </Title>
        <RoleBasicInfo
          role={{ name: role?.name, description: role?.description }}
        />
      </BasicInfoContainer>
      <FeaturesContainer>
        <Title>
          <Typography.Headline8 weight={700} color="neutral.20">
            Níveis de permissão
          </Typography.Headline8>
        </Title>
        <RolePermissionsSummary features={features} />
      </FeaturesContainer>
    </PageContainer>
  );
};
