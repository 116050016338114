// getAdministratorsRouter
import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback } from "react";

export const useAdministratorsQuery = () => {
  const { data, mutate, isLoading } =
    trpc.getAdministratorsPaginated.useMutation();

  const fetch = useCallback(
    (input: {
      searchQuery?: string;
      page: number;
      pageSize: number;
      order?: "asc" | "desc";
      sortBy?: "name" | "status" | "documentNumber";
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os administradores",
            type: "error",
          });
        },
      });
    },
    [mutate]
  );

  return { data, fetch, isLoading };
};

export const useUnsetAdministrator = () => {
  const { mutate, isLoading } = trpc.unsetAdmin.useMutation();
  const fetch = useCallback(
    (input: { employeeId: string }) => mutate(input),
    [mutate]
  );

  return { fetch, isLoading };
};
