import { Divider } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
  padding: ${({ theme }) => theme.spacings.xs3};
`;

export const FeatureHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
export const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap ${({ theme }) => theme.spacings.xs3};
`;

export const PermissionList = styled.div`
  display: flex;
  flex-direction: column;
  gap ${({ theme }) => theme.spacings.xs4};
  padding-top: ${({ theme }) => theme.spacings.xs3};
`;

export const PermissionInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacings.xs3};
  padding-bottom: ${({ theme }) => theme.spacings.xs3};
  gap: 10px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacings.xs3};
  padding: 12px 16px 12px 16px;
  gap: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const FinalDivider = styled(Divider)`
  margin-top: ${({ theme }) => theme.spacings.xs};
`;
