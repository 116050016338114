import { RoleTypeTag } from "@components/index";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Role } from "src/types";
import { Container, Title, Wrapper } from "./styles";

interface HeaderProps {
  role?: Role;
}

export const Header = ({ role }: HeaderProps) => {
  if (!role) return null;
  return (
    <Container>
      <Wrapper>
        <Title>
          <Typography.Headline7 color="neutral.20">
            {role.name}
          </Typography.Headline7>
          <RoleTypeTag builtIn={role.builtIn} />
        </Title>

        <Typography.Body4 color="neutral.40">
          {role.description}
        </Typography.Body4>
      </Wrapper>
    </Container>
  );
};
