/* eslint-disable @typescript-eslint/no-explicit-any */
import { PageTemplate } from "@components/index";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ModalWarn } from "./components/ModalWarn";

import {
  Container,
  ContentContainer,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
  TextWrapper,
  TransferListArea,
} from "./styled";

import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { routes } from "src/routes";
import { Employee } from "src/types";
import PageRoleEmployeeTransferList from "./components/TransferList/transferList";
import {
  useEditRoleMembers,
  useEmployeeIdsByRoleQuery,
  useEmployeeQuery,
} from "./queries";

export const PageRoleEmployees = () => {
  const navigate = useNavigate();
  const [employeesParsedData, setEmployeesParsedData] = useState<Employee[]>(
    []
  );
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const { selectedCompany } = useSelectedCompany();
  const permissions = usePermissions();

  const { roleId } = useParams() as { roleId: string };

  const routesBread = [
    { label: "Gerenciar empresa", route: "/settings" },
    {
      label: "Acessos",
      route: routes.pageRoles + "/" + roleId,
    },
    { label: "Editar Perfil" },
  ];

  const [employeeSearchQuery, setEmployeeSearchQuery] = useState<string>("");

  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data: membersIds, isLoading: isLoadingEmployeesIdsByRole } =
    useEmployeeIdsByRoleQuery(roleId);

  const {
    data: employeesData,
    fetch: fetchEmployees,
    isLoading: isLoadingEmployees,
  } = useEmployeeQuery();

  const { editRoleMembers, isLoading } = useEditRoleMembers({
    redirectTo: `${routes.pageRoles}/${roleId}`,
  });

  useEffect(() => {
    fetchEmployees({
      companyId: permissions.isAdmin ? undefined : selectedCompany.id,
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      ...(employeeSearchQuery && { searchQuery: employeeSearchQuery }),
      includeProfilePicture: true,
    });
  }, [
    fetchEmployees,
    selectedCompany.id,
    permissions,
    employeeSearchQuery,
    pagination,
  ]);

  useEffect(() => {
    if (employeesData && membersIds) {
      setEmployeesParsedData(
        employeesData?.employees.map((employeeData) => {
          return {
            ...employeeData,
            metaData: {
              ...(membersIds.includes(employeeData.id) && { isDisabled: true }),
            },
          };
        })
      );
    }
  }, [membersIds, employeesData]);

  return (
    <PageTemplate
      routes={routesBread}
      footer={{
        cancelProps: {
          callback: () => navigate(routes.pageRoles + `/${roleId}`),
        },
        skipProps: {
          disabled: false,
          callback: () => {
            const hasEmployees = !!employeesData?.employees.length;

            if (!hasEmployees) setOpenModal(true);
            else navigate(routes.pageRoles + `/${roleId}`);
          },
        },
        goBackProps: {
          callback: () =>
            navigate(routes.pageRolesPermissions.replace(":roleId", roleId)),
        },
        confirmProps: {
          disabled: isLoading || Boolean(false),
          loading: isLoading,
          callback: () => {
            if (membersIds) {
              editRoleMembers({
                roleId,
                employeeIds: selectedEmployees.map((employee) => employee.id),
              });
            }
          },
        },
      }}
    >
      <Container>
        <StyledTitle variant="headline6">
          Editar perfil de permissão
        </StyledTitle>
        <ContentContainer>
          <TextWrapper>
            <StyledSubtitle variant="headline8">
              Adicionar pessoas
            </StyledSubtitle>
            <StyledDescription variant="body3">
              Escolha quais pessoas você gostaria de adicionar a esse perfil de
              permissão.
            </StyledDescription>
          </TextWrapper>
          <TransferListArea>
            <PageRoleEmployeeTransferList
              data={employeesParsedData || ([] as any[])}
              loading={isLoadingEmployees || isLoadingEmployeesIdsByRole}
              tableTitle="Todas as Pessoas da empresa"
              tableTagLabel={`${
                employeesData?.metadata?.totalCount || 0
              } itens`}
              onSearchChange={setEmployeeSearchQuery}
              pagination={pagination}
              onPaginationChange={setPagination}
              selected={selectedEmployees}
              setSelected={setSelectedEmployees}
              dataSize={employeesData?.metadata?.totalCount || 0}
            />
          </TransferListArea>
        </ContentContainer>
      </Container>
      <ModalWarn
        isOpen={openModal}
        loading={isLoading}
        onClose={() => setOpenModal(false)}
        onSubmit={() => {
          setOpenModal(false);
          navigate(routes.pageRoles + `/${roleId}`);
        }}
      />
    </PageTemplate>
  );
};
