import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  flex-direction: column;
  align-items: center;
  gap ${({ theme }) => theme.spacings.xs2};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const NextStepsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs4};
`;

export const NextStepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs};
`;
