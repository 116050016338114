import styled from "styled-components";

export const MembersCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacings.xs3};
  padding: 12px 16px 12px 16px;
  gap: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const MembersContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs3};
`;
