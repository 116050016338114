import { Checkbox, PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  flex: 1;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const FeatureDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCheckBox = styled(Checkbox)`
  && {
    position: relative;
    top: -1px;
    width: ${({ theme }) => theme.spacings.s}
    height: ${({ theme }) => theme.spacings.s}
    margin: 0 8px 0 16px;

    & > div {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
`;

export const StyledPillButton = styled(PillButton)`
  border: none; !important
`;
