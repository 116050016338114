import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useCallback, useState } from "react";
import { useDeleteEmployeeFromRoleMutation } from "src/pages/PagePermissions/queries";
import { useEditRoleMembers } from "src/pages/PageRoleEmployees/queries";

interface UpdateEmployeeRolesProps {
  selectedEmployee: {
    employeeId: string;
    employeeName: string;
    employeeRoles: { id: string; name: string }[];
  };
  currentEmployeeRoles: { id: string; name: string }[];
  redirectTo: string;
}

export const useUpdateEmployeeRoles = ({
  selectedEmployee,
  currentEmployeeRoles,
  redirectTo,
}: UpdateEmployeeRolesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { selectedCompany } = useSelectedCompany();
  const { mutate: deleteEmployeeFromRole } = useDeleteEmployeeFromRoleMutation({
    companyId: selectedCompany.id,
  });
  const { editRoleMembers } = useEditRoleMembers({
    redirectTo,
  });

  const updateEmployeeRoles = useCallback(async () => {
    setIsLoading(true);
    const initialRoleIds = selectedEmployee.employeeRoles.map(
      (role) => role.id
    );
    const modifiedRoleIds = currentEmployeeRoles.map((role) => role.id);

    const rolesToAdd = modifiedRoleIds.filter(
      (id) => !initialRoleIds.includes(id)
    );
    const rolesToRemove = initialRoleIds.filter(
      (id) => !modifiedRoleIds.includes(id)
    );
    await Promise.all([
      ...rolesToRemove.map((id) =>
        deleteEmployeeFromRole({
          employee: {
            id: selectedEmployee.employeeId,
            name: selectedEmployee.employeeName,
          },
          role: {
            id,
            name:
              selectedEmployee.employeeRoles.find((role) => role.id === id)
                ?.name || "",
          },
        })
      ),
      ...rolesToAdd.map((id) =>
        editRoleMembers({
          roleId: id,
          employeeIds: [selectedEmployee.employeeId],
        })
      ),
    ]);
    setIsLoading(false);
  }, [
    deleteEmployeeFromRole,
    editRoleMembers,
    selectedCompany,
    selectedEmployee,
    currentEmployeeRoles,
  ]);

  return { updateEmployeeRoles, isLoading };
};
