import { Accordion, Divider } from "@flash-tecnologia/hros-web-ui-v2";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { FormValues } from "src/pages/PageRoleEdit/edit-role-context";
import { Feature } from "src/types";
import { FeatureAccordionHeader } from "../FeatureAccordionHeader/feature-accordion-header";
import { PermissionOption } from "../PermissionOption/permission-option";

interface FeatureAccordionProps {
  feature: Feature;
  searchQuery?: string;
  formik: FormikProps<FormValues>;
}

export const FeatureAccordion = ({
  feature,
  searchQuery = "",
  formik,
}: FeatureAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    if (searchQuery.length) setExpanded(true);
  }, [searchQuery]);
  return (
    <>
      <Accordion
        variant="soft"
        customHeader={
          <FeatureAccordionHeader
            feature={feature}
            expanded={expanded}
            formik={formik}
          />
        }
        title={feature.name}
        key={feature.name}
        onChange={() => {
          setExpanded(!expanded);
        }}
        expanded={expanded}
      >
        {feature.permissions.map((option, index) => (
          <>
            <PermissionOption
              key={`${option.name}_${option.description}_${index}`}
              permission={option}
              searchQuery={searchQuery}
              formik={formik}
            />
            {index < feature.permissions.length - 1 ? (
              <Divider orientation="horizontal" />
            ) : null}
          </>
        ))}
      </Accordion>
    </>
  );
};
