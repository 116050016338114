import { TextArea } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const CustomTextArea = styled(TextArea)`
  height: 70px; !important
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => theme.spacings.m};
  gap: ${({ theme }) => theme.spacings.xs};
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px 8px 12px;
  border-radius: ${({ theme }) => theme.spacings.xs3};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;
