import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CustomPillButton = styled.div`
  .MuiButtonBase-root {
    color: ${({ theme }) => theme.colors.status.negative[10]} !important;
    background-color: ${({ theme }) =>
      theme.colors.status.negative[90]} !important;
  }
  .MuiButtonBase-root > svg {
    color: ${({ theme }) => theme.colors.status.negative[10]} !important;
  }
`;
