import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 12px;

  .MuiTabs-root {
    margin-bottom: 4px !important;
  }
`;
