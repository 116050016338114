import { useState } from "react";
import { PaginationState } from "src/pages/PagePermissions/types";
import { TableViews } from "../../../types";

export const usePermissionsTable = () => {
  const [search, setSearch] = useState<string>("");
  const [tableView, setTableView] = useState<TableViews>(TableViews.ALL);
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });

  const pageSizeOptions = [
    { label: "5 itens", value: 5 },
    { label: "10 itens", value: 10 },
    { label: "25 itens", value: 25 },
  ];

  return {
    search,
    setSearch,
    pagination,
    setPagination,
    pageSizeOptions,
    tableView,
    setTableView,
  };
};
