import { useEffect, useState } from "react";
import * as Styled from "./styles";

import { useNavigate } from "react-router-dom";
import { useEmployeeQuery } from "../PageRoleEmployees/queries";

import { ModalOTP, useModalOTPActions } from "@components/ModalOTP";
import { PageTemplate } from "@components/PageTemplate";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { dispatchToast } from "@utils/dispatchToast";
import { routes } from "src/routes";
import { Employee } from "src/types";
import PageSetAdminsTransferList from "./components/TransferList/transferList";
import { useGetAdminsIds, useSetAdministrator } from "./queries";
import { TransferListArea } from "./styles";

const routesBread = [
  { label: "Gerenciar empresa", route: "/settings" },
  {
    label: "Super administradores",
    route: "/settings/permissions/administrators",
  },
  { label: "Atribuir novo super administrador" },
];

const PageSetAdmins = () => {
  const otpRef = useModalOTPActions();

  const [employeeSearchQuery, setEmployeeSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [employeesParsedData, setEmployeesParsedData] = useState<Employee[]>(
    []
  );
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 10,
  });
  const {
    data: employeesData,
    fetch: fetchEmployees,
    isLoading: isLoadingEmployees,
  } = useEmployeeQuery();

  const { fetch: setAdmin } = useSetAdministrator();

  const { data: adminIds, isLoading: isLoadingAdmins } = useGetAdminsIds();

  const isLoading = isLoadingEmployees || isLoadingAdmins;

  useEffect(() => {
    fetchEmployees({
      searchQuery: employeeSearchQuery,
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      includeProfilePicture: true,
    });
  }, [fetchEmployees, employeeSearchQuery, pagination]);

  useEffect(() => {
    if (employeesData && adminIds) {
      setEmployeesParsedData(
        employeesData?.employees.map((employeeData) => {
          return {
            ...employeeData,
            metaData: {
              ...(adminIds?.includes(employeeData.id) && {
                isDisabled: true,
              }),
            },
          };
        })
      );
    }
  }, [adminIds, employeesData]);

  const assignAdmins = async (confirmationCode: string) => {
    try {
      const adminsToAdd = selectedEmployees;

      await Promise.all(
        adminsToAdd.map(({ id }) =>
          setAdmin({ employeeId: id, confirmationCode })
        )
      );
      dispatchToast({
        type: "success",
        content: "Administradores adicionados com sucesso!",
      });
    } catch {
      dispatchToast({
        content: "Não foi possível reatribuir administradores",
        type: "error",
      });
    }

    navigate(routes.pageGlobalAdmins);
  };

  return (
    <PageTemplate
      routes={routesBread}
      footer={{
        cancelProps: {
          callback: () => navigate(routes.pageInitial),
        },

        confirmProps: {
          title: "Atribuir novos super administradores",
          loading: isLoading,
          callback: async () => {
            otpRef.current?.openModalIfNeeded({
              onConfirmCallback: (confirmationCode) => {
                assignAdmins(confirmationCode);
              },
            });
          },
        },
        goBackProps: {
          callback: () => navigate(routes.pageGlobalAdmins),
        },
      }}
    >
      <Styled.Spacer height={40} />
      <Typography variant="headline6">
        Atribuir novo super administrador
      </Typography>
      <Styled.Spacer height={32} />
      <Styled.Row>
        <Styled.Column>
          <Styled.PrimaryTypography variant="headline7">
            Atribuir administradores
          </Styled.PrimaryTypography>
          <Styled.Spacer height={8} />
          <Typography variant="body3">
            Escolha quais pessoas você gostaria de atribuir ao papel de super
            administrador. O super administrador tem acesso a informações e
            configurações de <strong>todas as empresas do grupo.</strong>
          </Typography>
        </Styled.Column>
        <TransferListArea>
          <PageSetAdminsTransferList
            data={employeesParsedData}
            dataSize={employeesData?.metadata.totalCount || 0}
            loading={isLoading}
            onPaginationChange={setPagination}
            onSearchChange={setEmployeeSearchQuery}
            pagination={pagination}
            selected={selectedEmployees}
            setSelected={setSelectedEmployees}
            tableTagLabel={`${employeesData?.metadata?.totalCount || 0} itens`}
            tableTitle="Todas as Pessoas da empresa"
          />
        </TransferListArea>
      </Styled.Row>
      <ModalOTP
        ref={otpRef}
        subtitle="Para a segurança dos dados sensíveis da sua empresa, você precisa confirmar sua identidade para acessar a página de Acessos e Permissões."
      />
    </PageTemplate>
  );
};

export default PageSetAdmins;
