type Step = {
  title: string;
  description: string;
};

interface CustomStepperProps {
  steps: Step[];
  activeStep: number;
}

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StepContent,
  StepDescription,
  StepIcon,
  StepperContainer,
  StepTitle,
  StepWrapper,
  VerticalLine,
} from "./styles";

export const CustomStepper = ({ steps, activeStep }: CustomStepperProps) => {
  return (
    <StepperContainer>
      {steps.map((step, index) => {
        const isActive = index === activeStep;
        const isCompleted = index < activeStep;
        const isLast = index === steps.length - 1;

        return (
          <StepWrapper key={step.title} $active={isActive} $isLast={isLast}>
            <StepIcon $isActive={isActive} $isCompleted={isCompleted}>
              {isCompleted ? (
                <Icons size={16} name="IconCircleCheck" />
              ) : isActive ? (
                <Icons size={16} name="IconCircleDot" />
              ) : (
                <Icons size={16} name="IconCircleDotted" />
              )}
            </StepIcon>

            <StepContent>
              <StepTitle $isActive={isActive} $isCompleted={isCompleted}>
                <Typography.Body4>{step.title}</Typography.Body4>
              </StepTitle>
              <StepDescription $isActive={isActive}>
                <Typography.Caption color="neutral.40">
                  {step.description}
                </Typography.Caption>
              </StepDescription>
            </StepContent>

            {!isLast && <VerticalLine $isActive={isActive} />}
          </StepWrapper>
        );
      })}
    </StepperContainer>
  );
};
