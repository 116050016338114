import {
  GenericProfilePicture,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTransferList } from "../../Root";

import { WarningModal } from "@components/WarningModal/warning-modal";
import { useCachedRole } from "@utils/useCachedRole";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  BodyStyled,
  EmptyListContainer,
  IconStyled,
  List,
  ListItemName,
  ListItemStyled,
  ListItemTrashStyled,
} from "./styled";
import { useDeleteActions } from "./useDeleteActions";

interface ModalConfig {
  title: string;
  description: string;
  onSubmit: () => void;
}

const ListItem = ({
  data,
}: {
  data: { id: string; name: string; metaData?: { isMember: boolean } };
}) => {
  const { roleId } = useParams() as { roleId: string };
  const location = useLocation();
  const isAdminPath = location.pathname.split("/").includes("administrators");
  const { removeSelected } = useTransferList();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { deleteSuperAdmin, deleteEmployeeFromRole } = useDeleteActions();
  const cachedRole = useCachedRole(roleId);

  const { title, description, onSubmit }: ModalConfig = useMemo(() => {
    return {
      title: isAdminPath
        ? "Você tem certeza que deseja remover esta pessoa do perfil de super administradores?"
        : "Você tem certeza que deseja remover esta pessoa deste perfil de permissão?",
      description: isAdminPath
        ? "Ao desvincular esta pessoa do perfil de super administradores, ela não terá mais permissão para gerenciar informações e configurações das empresas do grupo econômico. Certifique-se de que essa alteração é essencial."
        : "Ao desvincular esta pessoa do perfil, ela não terá mais as permissões vinculadas ao perfil. Certifique-se de que essa alteração é essencial.",
      onSubmit: isAdminPath
        ? () => {
            deleteSuperAdmin(data.id);
            setModalIsOpen(false);
            removeSelected([data]);
          }
        : () => {
            if (roleId) {
              deleteEmployeeFromRole({
                role: { id: roleId, name: cachedRole?.name || "" },
                employee: { id: data.id, name: data.name },
              });
              removeSelected([data]);
              setModalIsOpen(false);
            }
          },
    };
  }, [
    location,
    isAdminPath,
    roleId,
    deleteEmployeeFromRole,
    removeSelected,
    setModalIsOpen,
  ]);

  return (
    <>
      <ListItemStyled key={data.id}>
        <GenericProfilePicture size={40} name={data.name} />
        <ListItemName>
          <Typography variant="body4">{data.name}</Typography>
        </ListItemName>
        <ListItemTrashStyled>
          <Icons
            name="IconTrash"
            size={16}
            onClick={() => {
              if (data?.metaData?.isMember) {
                setModalIsOpen(true);
              } else {
                removeSelected([data]);
              }
            }}
          />
        </ListItemTrashStyled>
      </ListItemStyled>
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        modalSize="xs"
        isOpen={modalIsOpen}
        title={title}
        description={description}
        footerConfig={{
          icon: "IconTrash",
          text: "Sim, remover",
          buttonSize: "small",
          variant: "primary",
          iconColor: "negative",
        }}
        onClose={() => setModalIsOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

function EmptyList() {
  return (
    <>
      <EmptyListContainer>
        <IconStyled>
          <Icons name="IconUsers" size={40} color="#6B5B66" />
        </IconStyled>
        <Typography variant="headline9">Nenhuma pessoa selecionada</Typography>
        <BodyStyled>
          <Typography variant="body4">
            Selecione as pessoas que deseja adicionar na lista ao lado
          </Typography>
        </BodyStyled>
      </EmptyListContainer>
    </>
  );
}

export function SelectedList() {
  const { selectedFiltered } = useTransferList();
  return (
    <>
      {selectedFiltered.length ? (
        <List>
          {selectedFiltered.map((item) => (
            <ListItem data={item} />
          ))}
        </List>
      ) : (
        <EmptyList />
      )}
    </>
  );
}
