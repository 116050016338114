import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback, useContext, useState } from "react";
import { CreateRoleContext } from "../create-role-context";

export const useCreateRole = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useContext(CreateRoleContext);
  const { formik, goForward, setRoleId, roleId, members } = context;
  const { selectedCompany } = useSelectedCompany();

  const { mutateAsync } = trpc.createRole.useMutation();
  const { mutateAsync: editRoleAsync } = trpc.editRole.useMutation();

  const createRole = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await mutateAsync({
        companyId: selectedCompany.id,
        name: formik.values.name,
        description: formik.values.description,
      });
      setRoleId(data.id);
      goForward();
    } catch (error: any) {
      dispatchToast({
        type: "error",
        content: "Erro ao criar perfil",
        description: error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    formik.values,
    selectedCompany.id,
    mutateAsync,
    setIsLoading,
    setRoleId,
    goForward,
  ]);

  const editRole = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await editRoleAsync({
        roleId,
        name: formik.values.name,
        members: members.map((employee) => employee.id),
        permissions: formik.values.permissions,
        description: formik.values.description,
      });
      goForward();
    } catch (error: any) {
      dispatchToast({
        type: "error",
        content: "Erro ao criar perfil",
        description: error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    formik.values,
    selectedCompany.id,
    mutateAsync,
    setIsLoading,
    setRoleId,
    goForward,
  ]);

  return { ...context, isLoading, createRole, editRole };
};
