import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const CustomTableSpacing = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  && table > thead > tr > th:first-child {
    width: 1%;
  }
`;
