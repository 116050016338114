import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { getAccessTokenPayloadSync } from "@flash-tecnologia/hros-web-utility";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledBreadcrumbs } from "../PagePermissions/styled";
import { Header, Table } from "./components";
import { useAdministratorsQuery, useUnsetAdministrator } from "./queries";

import { ModalConfirmAction } from "@components/ModalConfirmAction";
import { Employee } from "src/types";
import * as Styled from "./styles";

export const PageGlobalAdmins = () => {
  const navigate = useNavigate();

  const {
    data: adminData,
    fetch: getAdmins,
    isLoading,
  } = useAdministratorsQuery();

  const [pageQuery, setPageQuery] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });

  const [parsedAdminData, setParsedAdminData] = useState<
    Array<Employee & { metadata: { isLoggedAdmin: boolean } }>
  >([]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [removeAdminModalisOpen, setRemoveAdminModalisOpen] =
    useState<boolean>(false);

  const admin = useMemo(() => getAccessTokenPayloadSync(), []);

  const [adminToBeDeleted, setAdminToBeDeleted] = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });

  const { fetch: removeAdmin } = useUnsetAdministrator();

  const hasOnlyOneAdmin = !!(
    adminData?.employees.length && adminData?.employees.length < 1
  );

  function getRemoveAdminModalInfo() {
    if (hasOnlyOneAdmin) {
      return {
        title: "Você não pode excluir o seu único super administrador",
        description: (
          <>
            Você precisa ter pelo menos um super administrador para gerenciar
            informações e configurações de{" "}
            <b>todas as empresas do grupo econômico.</b>
          </>
        ),
        submitText: (
          <>
            <span>Entendi</span>
          </>
        ),
        onSubmit: () => {
          setRemoveAdminModalisOpen(false);
        },
      };
    }
    return {
      title:
        "Tem certeza que deseja desvincular essa pessoa do perfil de super administrador?",
      description: (
        <>
          Ela não terá mais permissão para gerenciar informações e configurações
          das <b>empresas do grupo econômico.</b>
        </>
      ),
      submitText: (
        <>
          <span>Desvincular pessoa</span>
        </>
      ),
      onSubmit: async () => {
        removeAdmin({
          employeeId: adminToBeDeleted.id,
        });
        setAdminToBeDeleted({ id: "", name: "" });
        setRemoveAdminModalisOpen(false);
        getAdmins({
          page: pageQuery.page,
          pageSize: pageQuery.pageSize,
          searchQuery: searchTerm,
        });
      },
    };
  }

  const removeAdminModalInfo = getRemoveAdminModalInfo();

  const onRemoveAdmin = (data: { id: string; name: string }) => {
    if (admin.employeeId === data.id) {
    } else {
      setAdminToBeDeleted({
        id: data.id,
        name: data.name,
      });
      setRemoveAdminModalisOpen(true);
    }
  };

  const fetchData = useCallback(
    ({
      pageIndex,
      pageSize,
    }: {
      pageIndex?: number;
      pageSize?: number;
      sortBy?: Array<{
        id: string;
        desc?: boolean;
      }>;
    }) => {
      if (pageIndex !== undefined && pageSize !== undefined)
        setPageQuery({ pageSize, page: pageIndex });
    },
    [setPageQuery]
  );

  useEffect(() => {
    getAdmins({
      page: pageQuery.page,
      pageSize: pageQuery.pageSize,
      searchQuery: searchTerm,
    });
  }, [searchTerm, pageQuery, getAdmins]);

  useEffect(() => {
    if (adminData && admin) {
      setParsedAdminData(
        adminData.employees.map((employee) => ({
          ...employee,
          metadata: { isLoggedAdmin: admin.employeeId === employee.id },
        }))
      );
    }
  }, [adminData, admin]);

  return (
    <Styled.PageContainer>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton variant="neutral" onClick={() => navigate("/settings")}>
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="neutral">Super administradores</LinkButton>,
        ]}
      />
      <Header administratorsCount={adminData?.employees.length || 0} />
      <Table
        employees={parsedAdminData}
        fetchData={fetchData}
        hasRoles
        loading={isLoading}
        searchValue={searchTerm}
        onSearch={setSearchTerm}
        paginationInfo={{
          totalPages: adminData?.metadata.totalPages || 1,
        }}
        onRemoveAdmin={onRemoveAdmin}
      />
      <ModalConfirmAction
        isOpen={removeAdminModalisOpen}
        loading={false}
        title={removeAdminModalInfo.title}
        description={removeAdminModalInfo.description}
        submitText={removeAdminModalInfo.submitText}
        onClose={() => setRemoveAdminModalisOpen(false)}
        onSubmit={removeAdminModalInfo.onSubmit}
        submitButtonStyle={{
          margin: "auto 0",
          padding: "16px 24px",
          background: "var(--color-feedback-error-90)",
        }}
      />
    </Styled.PageContainer>
  );
};
