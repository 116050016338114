import { RoleBasicInfo } from "@components/RoleBasicInfo/role-basic-info";
import { RoleMembersSummary } from "@components/RoleMembersSummary/role-members-summary";
import { RolePermissionsSummary } from "@components/RolePermissionsSummary/role-permissions-summary";
import {
  Icons,
  PillButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { setEventTracking } from "@utils/eventTracking";
import { FormikProps } from "formik";
import { useEffect, useMemo } from "react";
import { Employee, RoleModule } from "src/types";
import { useTheme } from "styled-components";
import {
  CREATE_ROLE_STEPS,
  FormValues,
} from "../../pages/PageRoleCreate/create-role-context";
import { Container, SectionTitle, WarningTip } from "./styles";

interface ReviewStepProps {
  formik: FormikProps<FormValues>;
  members: Employee[];
  modules?: RoleModule[] | [];
  setStep: (step: number) => void;
  edit: boolean;
}

export const ReviewStep = ({
  formik,
  members,
  modules,
  setStep,
  edit,
}: ReviewStepProps) => {
  const theme = useTheme();
  const ONLY_ONE_MEMBER = members?.length === 1;

  const filteredFeatures = useMemo(() => {
    const permissions = formik.values.permissions;
    if (permissions && modules) {
      const permissionsSet = new Set(permissions);
      return modules?.flatMap((module) =>
        module.features
          .map((feature) => ({
            ...feature,
            permissions: feature.permissions.filter((permission) =>
              permissionsSet.has(permission.id)
            ),
          }))
          .filter((feature) => feature.permissions.length > 0)
      );
    }
    return [];
  }, [modules, formik.values]);

  useEffect(() => {
    if (ONLY_ONE_MEMBER) {
      edit
        ? setEventTracking("edit_role_review_step_only_member_warning")
        : setEventTracking("create_role_review_step_only_member_warning");
    }
  }, [ONLY_ONE_MEMBER]);

  return (
    <Container>
      <SectionTitle>
        <Typography.Headline8 color="neutral.20">
          Revisar informações básicas
        </Typography.Headline8>
        <PillButton
          variant="default"
          type="secondary"
          icon="IconPencil"
          size="small"
          onClick={() => setStep(CREATE_ROLE_STEPS.BASIC_INFO)}
        />
      </SectionTitle>
      <RoleBasicInfo
        role={{
          name: formik.values.name,
          description: formik.values.description,
        }}
      />
      <SectionTitle>
        <Typography.Headline8 color="neutral.20">
          Revisar níveis de permissão
        </Typography.Headline8>
        <PillButton
          variant="default"
          type="secondary"
          icon="IconPencil"
          size="small"
          onClick={() => setStep(CREATE_ROLE_STEPS.PERMISSIONS)}
        />
      </SectionTitle>
      <RolePermissionsSummary features={filteredFeatures} />
      <SectionTitle>
        <Typography.Headline8 color="neutral.20">
          Revisar membros que terão acesso à permissão
        </Typography.Headline8>
        <PillButton
          variant="default"
          type="secondary"
          icon="IconPencil"
          size="small"
          onClick={() => setStep(CREATE_ROLE_STEPS.MEMBERS)}
        />
      </SectionTitle>
      {ONLY_ONE_MEMBER && (
        <WarningTip>
          <Icons
            name="IconBulb"
            color={theme.colors.feedback.error[40]}
            size={24}
          />
          <Typography.Caption color="feedback.error.10">
            É recomendável adicionar várias pessoas a uma permissão, o que
            otimiza a gestão e assegura que todos tenham os acessos adequados.
            Clique no ícone de editar para voltar à etapa de adição de membros e
            selecionar mais pessoas para esta permissão.
          </Typography.Caption>
        </WarningTip>
      )}
      <RoleMembersSummary members={members.map((employee) => employee.name)} />
    </Container>
  );
};
