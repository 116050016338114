import { WarningModal } from "@components/WarningModal/warning-modal";
import {
  Icons,
  IconsProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { setEventTracking } from "@utils/eventTracking";
import { useCachedRole } from "@utils/useCachedRole";
import { useStableHover } from "@utils/useStableHover";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteEmployeeFromRoleMutation } from "src/pages/PagePermissions/queries";
import { Action } from "src/pages/PagePermissionsV2/components/Tabs/types";
import { useTheme } from "styled-components";
import { RoleMembersMenu } from "../MembersMenu/role-members-menu";
import { Container, QuickAction, Wrapper } from "./styles";

interface RoleMembersActionsProps {
  employeeName: string;
  employeeId: string;
}

export const RoleMembersActions = ({
  employeeName,
  employeeId,
}: RoleMembersActionsProps) => {
  const { roleId } = useParams() as { roleId: string };
  const { containerRef, isHovered } = useStableHover();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { selectedCompany } = useSelectedCompany();
  const { mutate: deleteEmployeeFromRole } = useDeleteEmployeeFromRoleMutation({
    companyId: selectedCompany.id,
  });
  const cachedRole = useCachedRole(roleId);
  const theme = useTheme();
  const navigate = useNavigate();

  const actions: Action[] = [
    {
      label: "Ver perfil da pessoa",
      icon: "IconEye" as IconsProps["name"],
      key: "view",
      onClick: () => navigate(`/employees/${employeeId}/profile`),
    },
    {
      label: "Remover membro desta permissão",
      icon: "IconUserMinus" as IconsProps["name"],
      key: "remove",
      onClick: () => setIsModalOpen(true),
    },
  ];

  return (
    <Wrapper>
      <Container ref={containerRef} $hovered={isHovered}>
        {isHovered ? (
          <>
            <QuickAction
              $borderColor={theme.colors.neutral[100]}
              onClick={() => navigate(`/employees/${employeeId}/profile`)}
            >
              <Typography.Caption weight={700}>
                Ver perfil da pessoa
              </Typography.Caption>
              <Icons name="IconEye" size={16} fill="transparent" />
            </QuickAction>
            <QuickAction
              $borderColor={theme.colors.feedback.error[40]}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <Typography.Caption color="feedback.error.40" weight={700}>
                Remover membro
              </Typography.Caption>
              <Icons
                name="IconUserMinus"
                size={16}
                fill="transparent"
                color={theme.colors.feedback.error[40]}
              />
            </QuickAction>
            <RoleMembersMenu actions={actions} isHovered={isHovered} />
          </>
        ) : (
          <>
            <Icons name="IconEye" size={16} fill="transparent" />
            <Icons name="IconUserMinus" size={16} fill="transparent" />
            <RoleMembersMenu actions={actions} isHovered={isHovered} />
          </>
        )}
      </Container>
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        modalSize="xs"
        isOpen={isModalOpen}
        title="Você tem certeza que deseja remover esta pessoa deste perfil de permissão?"
        description="Ao desvincular esta pessoa do perfil, ela perderá todas as permissões vinculadas ao perfil. Certifique-se de que essa alteração é necessária."
        footerConfig={{
          text: "Sim, remover",
          icon: "IconTrash",
          buttonSize: "small",
          variant: "primary",
          iconColor: "negative",
        }}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          if (roleId) {
            setEventTracking("role_page_remove_member_submit");
            deleteEmployeeFromRole({
              role: { id: roleId, name: cachedRole?.name || "" },
              employee: { id: employeeId, name: employeeName },
            });
            setIsModalOpen(false);
          }
        }}
      />
    </Wrapper>
  );
};
