import { EmployeeDetails } from "@components/EmployeesTable/components/EmployeeDetails/employee-details";
import { TagsColumn } from "@components/EmployeesTable/components/TagsColumn/tags-column";
import { useEmployeesTable } from "@components/EmployeesTable/hooks/useEmployeesTable";
import TransferList from "@components/TransferListV2";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetRoleMembers } from "src/pages/PageAddMembers/useGetRoleMembers";
import { TruncateText } from "src/pages/PagePermissionsV2/components/Tabs/components/TruncateText/truncate-text";
import { useEmployeeIdsByRoleQuery } from "src/pages/PageRoleEmployees/queries";
import { Employee } from "src/types";
import { Container, CustomTableSpacing, CustomTableTitle } from "./styles";

interface ChooseMembersProps {
  members: Employee[];
  setMembers: Dispatch<SetStateAction<Employee[]>>;
}

export const ChooseMembersStep = ({
  members,
  setMembers,
}: ChooseMembersProps) => {
  const { roleId } = useParams() as { roleId: string };
  const { isAdmin } = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const { data: membersIds, isLoading: membersIdsLoading } =
    useEmployeeIdsByRoleQuery(roleId);

  const { roleMembers, isLoading: roleMembersLoading } =
    useGetRoleMembers(membersIds);

  const {
    data: employeesData,
    fetchEmployees,
    isLoading: isLoadingEmployees,
    pagination,
    setPagination,
    searchQuery,
    setSearch,
  } = useEmployeesTable();

  useEffect(() => {
    fetchEmployees({
      companyId: selectedCompany.id!,
      ...(isAdmin && { restrictToCompany: false }),
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      searchQuery: searchQuery,
    });
  }, [pagination, searchQuery, selectedCompany]);

  useEffect(() => {
    if (roleMembers?.length) {
      setMembers(
        roleMembers.map((employee) => ({
          id: employee.id,
          email: employee.email ? employee.email : "",
          name: employee.name,
          status: employee.status,
          metaData: { isMember: true },
        }))
      );
    }
  }, [roleMembers]);

  const parsedData = useMemo(() => {
    if (employeesData?.employees) {
      return employeesData?.employees.map((employee) => ({
        ...employee,
        metaData: {
          ...(employee.status === "INACTIVE" && { isDisabled: true }),
          ...(membersIds?.includes(employee.id) && {
            isMember: true,
          }),
        },
      }));
    }
    return [];
  }, [employeesData, membersIds]);

  const columns = [
    {
      header: "Pessoa",
      accessorKey: "name",
      minSize: 180,
      cell: ({ row }: any) => (
        <EmployeeDetails
          employeeDetails={{
            name: row?.original?.name,
            documentNumber: row?.original?.documentNumber,
            status: row?.original?.status,
          }}
        />
      ),
    },
    {
      header: "Cargo",
      accessorKey: "role",
      minSize: 100,
      cell: ({ row }: any) => (
        <Typography.Body4 weight={600} color="neutral.20">
          <TruncateText text={row?.original?.role} limit={26} />
        </Typography.Body4>
      ),
    },
    {
      header: "Empresa que faz parte",
      accessorKey: "company",
      minSize: 150,
      cell: ({ row }: any) => (
        <TagsColumn
          tags={[row?.original?.company]}
          emptyLabel="Nome da empresa"
          tagVariant="gray"
        />
      ),
    },
  ];
  return (
    <Container>
      <TransferList.Root selected={members} setSelected={setMembers}>
        <CustomTableSpacing>
          <CustomTableTitle>
            <Typography.Headline8 color="neutral.20">
              Para quais pessoas você quer aplicar esta permissão?
            </Typography.Headline8>
            <Typography.Body4 color="neutral.30">
              Selecione abaixo as pessoas que devem ter acesso a esta permissão.
            </Typography.Body4>
          </CustomTableTitle>
          <TransferList.Table
            columns={columns}
            data={parsedData}
            loading={isLoadingEmployees}
            pagination={pagination}
            onSearchChange={setSearch}
            onPaginationChange={setPagination}
            tableTagLabel={""}
            tableTitle={""}
            dataSize={employeesData?.metadata.totalCount || 0}
          />
        </CustomTableSpacing>
        <TransferList.SelectedListArea />
      </TransferList.Root>
    </Container>
  );
};
