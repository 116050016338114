import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { ButtonBase } from "@mui/material";

import {
  FlexColumn,
  FlexRow,
  IconText,
  OptionsContainer,
  StyledCheckbox,
  StyledTooltip,
  StyledTypography,
  TitleWrapper,
} from "./styled";

import { Feature, Permission } from "../../types";

interface OptionsProps {
  title: {
    icon: React.ReactNode;
    text: string;
  };
  selectedPermissions: string[];
  permissions: Permission[];
  readOnly?: boolean;
  handleChange: ({ ids, checked }: { ids: string[]; checked: boolean }) => void;
}

const Options = ({
  selectedPermissions,
  title,
  permissions,
  handleChange,
  readOnly,
}: OptionsProps) => {
  const allChecked = permissions.every((permission) =>
    selectedPermissions.includes(permission.id)
  );

  return (
    <OptionsContainer>
      <TitleWrapper>
        <IconText>
          {title.icon}
          <StyledTypography variant="body3">{title.text}</StyledTypography>
        </IconText>
        <FlexRow>
          <StyledCheckbox
            disableRipple={true}
            disabled={readOnly}
            onChange={(_, checked) => {
              handleChange({
                ids: permissions.map((permission) => permission.id),
                checked,
              });
            }}
            checked={allChecked}
          />
          <StyledTypography variant="body3">Marcar todas</StyledTypography>
        </FlexRow>
      </TitleWrapper>
      <FlexColumn>
        {permissions.map((permission) => {
          return (
            <FlexRow key={permission.id}>
              <StyledCheckbox
                disableRipple={true}
                disabled={readOnly}
                onChange={(_, checked) =>
                  handleChange({ ids: [permission.id], checked })
                }
                checked={selectedPermissions.includes(permission.id)}
              />
              <StyledTypography variant="body3">
                {permission.name}
              </StyledTypography>
              {permission?.description && (
                <StyledTooltip
                  title={permission.description}
                  placement="right-start"
                >
                  <ButtonBase>
                    <Icons
                      name="IconInfoCircle"
                      fill="transparent"
                      color="#444749"
                      size={16}
                    />
                  </ButtonBase>
                </StyledTooltip>
              )}
            </FlexRow>
          );
        })}
      </FlexColumn>
    </OptionsContainer>
  );
};

interface PermissionOptionsProps {
  selectedPermissions: string[];
  group: Feature;
  readOnly?: boolean;
  handleChange: ({ ids, checked }: { ids: string[]; checked: boolean }) => void;
}

export const PermissionOptions = ({
  selectedPermissions,
  group,
  readOnly,
  handleChange,
}: PermissionOptionsProps) => {
  const hasPageActions = !!group.permissions?.length;

  return (
    <FlexColumn>
      {hasPageActions && (
        <Options
          title={{
            icon: <Icons name="IconClick" fill={"transparent"} size={24} />,
            text: "Ações da página",
          }}
          permissions={group.permissions}
          selectedPermissions={selectedPermissions}
          readOnly={readOnly}
          handleChange={handleChange}
        />
      )}
    </FlexColumn>
  );
};
