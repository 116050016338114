import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { EmployeeStatus } from "src/types";
import { InnerCircle, OuterCircle } from "./styles";

export const StatusTagV2 = ({ status }: { status: EmployeeStatus }) => {
  const statusLabel = {
    ACTIVE: "Ativo",
    INACTIVE: "Inativo",
    INVITATION_EXPIRED: "Convite Expirado",
    INVITATION_SENT: "Convite Enviado",
    INVITATION_SCHEDULED: "Convite Agendado",
    INVITATION_PENDING: "Aguardando Convite",
  };
  return (
    <Tooltip title={statusLabel[status]} placement="top">
      <OuterCircle $status={status}>
        <InnerCircle $status={status}></InnerCircle>
      </OuterCircle>
    </Tooltip>
  );
};
