import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";

export const useGetModules = () => {
  const { data, isLoading } = trpc.getModules.useQuery(undefined, {
    onError: (error) => {
      dispatchToast({
        type: "error",
        content: "Erro ao buscar módulos de permissões",
        description: error.message,
      });
    },
  });
  return { data, isLoading };
};
