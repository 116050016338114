import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding-top: ${({ theme }) => theme.spacings.xs};

  @media screen and (min-width: 900px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs4};

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  max-width: 80vh;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;
