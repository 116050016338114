import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  FooterCancelButton,
  FooterContainer,
  SubmitButton,
  SubmitButtonText,
} from "./styled";

export const Footer = ({
  onClose,
  onSubmit,
  loading,
  disabled,
  isMobile,
}: FooterProps) => {
  return (
    <FooterContainer>
      <FooterCancelButton variant="default" onClick={onClose}>
        Voltar e adicionar
      </FooterCancelButton>
      <SubmitButton
        variant={"primary"}
        buttonType={"primary"}
        size={isMobile ? "medium" : "large"}
        style={{ margin: "auto 0" }}
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
        disableRipple
        disableTouchRipple
      >
        <SubmitButtonText>
          Concluir
          <Icons name="IconCheck" fill="transparent" />
        </SubmitButtonText>
      </SubmitButton>
    </FooterContainer>
  );
};

type FooterProps = {
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  disabled: boolean;
  isMobile: boolean;
};
