import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

export const StatusTag = ({ status }: { status: string }) => {
  const style = { minWidth: "max-content" };
  switch (status) {
    case "ACTIVE":
      return (
        <Tag variant="success" hasLeftDotIcon={true} as={"label"} style={style}>
          Ativo
        </Tag>
      );

    case "INACTIVE":
      return (
        <Tag variant="error" hasLeftDotIcon={true} as={"label"} style={style}>
          Inativo
        </Tag>
      );

    case "INVITATION_SENT":
      return (
        <Tag variant="gray" hasLeftDotIcon={true} as={"label"} style={style}>
          Convite enviado
        </Tag>
      );

    case "INVITATION_EXPIRED":
      return (
        <Tag variant="gray" hasLeftDotIcon={true} as={"label"} style={style}>
          Convite expirado
        </Tag>
      );

    case "INVITATION_PENDING":
      return (
        <Tag variant="info" hasLeftDotIcon={true} as={"label"} style={style}>
          Aguardando convite
        </Tag>
      );

    default:
      return <></>;
  }
};
