import { WarningModal } from "@components/WarningModal/warning-modal";
import {
  Icons,
  IconsProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { setEventTracking } from "@utils/eventTracking";
import { useStableHover } from "@utils/useStableHover";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteRoleMutation } from "src/pages/PagePermissions/queries";
import { routesV2 } from "src/routes";
import { useTheme } from "styled-components";
import { Action } from "../../../../types";
import { PermissionsMenu } from "./components/PermissionsMenu/permissions-menu";
import { Container, QuickAction, Wrapper } from "./styles";

interface PermissionsActionsProps {
  builtIn?: boolean;
  roleId: string;
}

export const PermissionsActions = ({
  builtIn,
  roleId,
}: PermissionsActionsProps) => {
  const { selectedCompany } = useSelectedCompany();
  const { containerRef, isHovered } = useStableHover();
  const theme = useTheme();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { mutate: deleteRole } = useDeleteRoleMutation({
    companyId: selectedCompany.id,
  });

  const handleDeleteRole = useCallback(() => {
    setEventTracking("permissions_page_role_deleted");
    deleteRole({ roleId });
    setModalIsOpen(false);
  }, [roleId]);

  const actions: Action[] = useMemo(() => {
    const options = [
      {
        label: "Ver detalhes",
        icon: "IconEye" as IconsProps["name"],
        key: "view",
        onClick: () =>
          navigate(routesV2.pageRolesPermissions.replace(":roleId", roleId), {
            state: { preSelectedTab: 1 },
          }),
      },
      {
        label: "Editar membros via plataforma",
        icon: "IconUserPlus" as IconsProps["name"],
        key: "add",
        onClick: () => {
          navigate(routesV2.pageRolesAddMembers.replace(":roleId", roleId));
        },
      },
    ];
    if (!builtIn) {
      options.push({
        label: "Editar perfil de permissão",
        icon: "IconPencil" as IconsProps["name"],
        key: "edit",
        onClick: () =>
          navigate(routesV2.pageRolesEdit.replace(":roleId", roleId)),
      });
      options.push({
        label: "Excluir perfil de permissão",
        icon: "IconTrash" as IconsProps["name"],
        key: "delete",
        onClick: () => setModalIsOpen(true),
      });
    }

    return options;
  }, [builtIn, setModalIsOpen]);

  return (
    <Wrapper>
      <Container ref={containerRef} $hovered={isHovered}>
        {isHovered ? (
          <>
            <QuickAction
              onClick={() =>
                navigate(
                  routesV2.pageRolesPermissions.replace(":roleId", roleId)
                )
              }
            >
              <Typography.Caption weight={700}>Ver membros</Typography.Caption>
              <Icons name="IconEye" size={16} fill="transparent" />
            </QuickAction>
            <QuickAction
              onClick={() =>
                navigate(
                  routesV2.pageRolesAddMembers.replace(":roleId", roleId)
                )
              }
            >
              <Typography.Caption color="secondary.50" weight={700}>
                Editar membros
              </Typography.Caption>
              <Icons
                name="IconUserPlus"
                size={16}
                fill="transparent"
                color={theme.colors.secondary[50]}
              />
            </QuickAction>
            <PermissionsMenu
              actions={actions}
              isHovered={isHovered}
              builtIn={builtIn || false}
            />
          </>
        ) : (
          <>
            <Icons name="IconEye" size={16} fill="transparent" />
            <Icons name="IconUserPlus" size={16} fill="transparent" />
            <PermissionsMenu
              actions={actions}
              isHovered={isHovered}
              builtIn={builtIn || false}
            />
          </>
        )}
      </Container>
      <WarningModal
        iconConfig={{ name: "IconAlertCircle", size: 52, variant: "negative" }}
        auditConfirm="Estou ciente de que os membros deste perfil perderão os acessos atribuídos por ele."
        title={"Você tem certeza que deseja excluir este perfil de permissão?"}
        description={
          "Ao excluir este perfil de permissão, todos os membros associados perderão automaticamente os acessos vinculados a ele. Caso algum membro não tenha outro perfil associado, ele será convertido para o perfil de membro básico. Certifique-se de que esta ação é realmente necessária, pois ela é irreversível."
        }
        confirmWord="EXCLUIR"
        isOpen={modalIsOpen}
        modalSize="xs"
        footerConfig={{
          icon: "IconTrash",
          text: "Sim, excluir",
          variant: "primary",
          buttonSize: "small",
          iconColor: "negative",
        }}
        onSubmit={handleDeleteRole}
        onClose={() => {
          setModalIsOpen(false);
        }}
      />
    </Wrapper>
  );
};
