import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => theme.spacings.xs4};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  jusfity-content: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  max-width: 400px;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacings.xs};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;
  padding: 12px 40px;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  overflow-y: auto;
  max-height: 60vh;
`;
