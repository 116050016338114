import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  jusfity-content: center;
  align-items: center;
  width: 25%;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const StyledCheckBox = styled(Checkbox)`
  && {
    position: relative;
    top: -1px;
    width: ${({ theme }) => theme.spacings.s};
    height: ${({ theme }) => theme.spacings.s};
    margin: 0 8px 0 16px;

    & > div {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
`;
