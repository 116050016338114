import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import {
  BackButton,
  Container,
  ContinueButton,
  StyledLinkButton,
  Wrapper,
} from "./styled";

type ButtonProps = {
  title?: React.ReactNode;
  callback?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
};

export type FooterProps = {
  cancelProps: ButtonProps;
  goBackProps: ButtonProps;
  confirmProps: ButtonProps;
  skipProps?: ButtonProps;
};

export const Footer = ({
  cancelProps,
  goBackProps,
  confirmProps,
  skipProps,
}: FooterProps) => {
  return (
    <Container>
      <Wrapper>
        <StyledLinkButton
          variant="default"
          disabled={cancelProps.disabled || cancelProps.loading || false}
          onClick={cancelProps.callback}
        >
          {cancelProps.title ? cancelProps.title : "Cancelar"}
        </StyledLinkButton>
        {skipProps && (
          <StyledLinkButton
            variant="default"
            disabled={skipProps.disabled || skipProps.loading || false}
            onClick={skipProps.callback}
          >
            {skipProps.title ? skipProps.title : "Pular e finalizar"}
          </StyledLinkButton>
        )}
      </Wrapper>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          loading={goBackProps.loading || false}
          disabled={goBackProps.disabled || goBackProps.loading || false}
          onClick={goBackProps.callback}
        >
          {goBackProps.title ? (
            goBackProps.title
          ) : (
            <>
              <Icons name="IconArrowLeft" />
              Voltar
            </>
          )}
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          loading={confirmProps.loading || false}
          disabled={confirmProps.disabled || confirmProps.loading || false}
          onClick={confirmProps.callback}
        >
          {confirmProps.title ? (
            confirmProps.title
          ) : (
            <>
              Concluir <Icons name="IconCheck" fill="transparent" />
            </>
          )}
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
