import { ModalOTP, useModalOTPActions } from "@components/ModalOTP";
import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRolesQuery } from "../PagePermissions/queries";
import { useEmployeeQuery } from "../PageRoleEmployees/queries";
import { Banner } from "./components/Banner/permissions-banner";
import { Header } from "./components/Header/permissions-header";
import { PagePermissionsSkeleton } from "./components/Skeleton/permissions-page-skeleton";
import { PermissionsTabs } from "./components/Tabs/permissions-tabs";

interface LocationState {
  listRolesEnabled: boolean;
}

export const PagePermissionsV2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRolesQueryEnabled] = useState(
    () => (location.state as LocationState)?.listRolesEnabled ?? true
  );
  const otpRef = useModalOTPActions();
  const { isAdmin } = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const { data: permissions, isLoading: permissionsLoading } = useRolesQuery({
    companyId: selectedCompany.id,
    enabled: isRolesQueryEnabled,
  });
  const {
    data: employeesData,
    isLoading: employeesLoading,
    fetch: fetchEmployees,
  } = useEmployeeQuery();

  const pageLoading = permissionsLoading || employeesLoading;

  useEffect(() => {
    otpRef.current?.openModalIfNeeded({
      onCancelCallback: () => navigate("/home"),
    });
  }, [otpRef, pageLoading]);

  useEffect(() => {
    if (selectedCompany) {
      fetchEmployees({ companyId: selectedCompany.id, page: 1, pageSize: 10 });
    }
  }, [selectedCompany]);

  if (pageLoading) return <PagePermissionsSkeleton />;

  return (
    <PageContainer>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton variant="neutral" onClick={() => navigate("/settings")}>
            Configurações
          </LinkButton>,
          <LinkButton variant="neutral">Perfis de permissão</LinkButton>,
        ]}
      />
      <Header />
      {isAdmin ? <Banner /> : null}
      <PermissionsTabs
        selectedCompany={selectedCompany}
        permissions={permissions}
        employeesData={employeesData}
        isLoading={pageLoading}
      />
      <ModalOTP
        ref={otpRef}
        subtitle="Para a segurança dos dados sensíveis da sua empresa, você precisa confirmar sua identidade para acessar a página de Acessos e Permissões."
      />
    </PageContainer>
  );
};
